export const MethodType = {
  GET: "GET", //get_one
  POST: "POST", //get_all
  PATCH: "PATCH", //update
  PUT: "PUT", //create
  DELETE: "DELETE", //delete
  FORMDATA: "FORMDATA", //
};

export const status_codes = {
  Error: "500",
  InvalidData: "422",
  Success: "200",
  Unauthorized: "401",
  InvalidMediaType: "415",
  Forbidden: "403",
  NotFound: "404",
  Duplicate: "409",
};

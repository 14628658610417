import React, { useState } from "react";
import { Form } from "antd";
import MuiSkeleton from "../../Loading/mui_skeleton";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";

const MuiTimePicker = (props) => {
  const { field, message, is_required, is_loading, time, onChange, label } =
    props;

  return (
    <Form.Item
      name={field}
      rules={[
        {
          required: is_required || false,
          message: message || "",
        },
      ]}
      className="no_margin_form_input"
    >
      {is_loading ? (
        <MuiSkeleton
          variant_shape={"rectangle"}
          width={"100%"}
          height={50}
          animation_type={"wave"} //"wave" or "pulse"
          color={"#f1f1f1"}
          border_radius={10}
        />
      ) : (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            // mask="hh:mm A"
            // placeholder="Select Time"
            label={label}
            value={time}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
            onChange={onChange}
            minutesStep={30}
          />
        </LocalizationProvider>
      )}
    </Form.Item>
  );
};
export default MuiTimePicker;

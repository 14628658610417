import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import MainMenu from "./MainMenu";
import useStyles from "./sidebar-jss";
import { brand, dummy } from "@helpers/constants";

function SidebarContent(props) {
  const { classes, cx } = useStyles();
  const [transform, setTransform] = useState(0);

  const handleScroll = (event) => {
    const scroll = event.target.scrollTop;
    setTransform(scroll);
  };

  useEffect(() => {
    const mainContent = document.getElementById("sidebar");
    mainContent.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {
    turnDarker,
    drawerPaper,
    toggleDrawerOpen,
    loadTransition,
    leftSidebar,
    dataMenu,
    status,
    anchorEl,
    openMenuStatus,
    closeMenuStatus,
    changeStatus,
    isLogin,
  } = props;

  const setStatus = (st) => {
    switch (st) {
      case "online":
        return classes.online;
      case "idle":
        return classes.idle;
      case "bussy":
        return classes.bussy;
      default:
        return classes.offline;
    }
  };

  return (
    <div
      className={cx(
        classes.drawerInner,
        !drawerPaper ? classes.drawerPaperClose : ""
      )}
    >
      <div className={classes.drawerHeader}>
        <NavLink
          to="/"
          className={cx(
            classes.brand,
            classes.brandBar,
            turnDarker && classes.darker
          )}
        >
          <img
            // src={require("../../../assets/images/logo.png")}
            src={require("../../../../assets/images/v.png")}
            alt={brand.name}
          />
          {brand.name}
        </NavLink>
        {/* {isLogin && (
          <div
            className={cx(classes.profile, classes.user)}
            style={{
              opacity: 1 - transform / 100,
              marginTop: transform * -0.3,
            }}
          >
            <img
              alt={dummy.user.name}
              // src={"/images/avatars/pp_boy.svg"}
              src={require("../../../../assets/images/v.png")}
              // className={cx(classes.avatar, classes.bigAvatar)}
              style={{
                width: 40,
              }}
            />
            <div>
              <h4>{dummy.user.name}</h4>
            </div>
          </div>
        )} */}
      </div>
      <div
        id="sidebar"
        className={cx(
          classes.menuContainer,
          leftSidebar && classes.rounded
          // isLogin && classes.withProfile
        )}
      >
        <MainMenu
          loadTransition={loadTransition}
          dataMenu={dataMenu}
          toggleDrawerOpen={toggleDrawerOpen}
        />
      </div>
    </div>
  );
}

SidebarContent.propTypes = {
  // drawerPaper: PropTypes.bool.isRequired,
  // turnDarker: PropTypes.bool,
  // toggleDrawerOpen: PropTypes.func,
  // loadTransition: PropTypes.func,
  // leftSidebar: PropTypes.bool.isRequired,
  // dataMenu: PropTypes.array.isRequired,
  // status: PropTypes.string.isRequired,
  // anchorEl: PropTypes.object,
  // openMenuStatus: PropTypes.func.isRequired,
  // closeMenuStatus: PropTypes.func.isRequired,
  // changeStatus: PropTypes.func.isRequired,
  // isLogin: PropTypes.bool,
};

SidebarContent.defaultProps = {
  turnDarker: false,
  toggleDrawerOpen: () => {},
  loadTransition: () => {},
  anchorEl: null,
  isLogin: true,
};

export default SidebarContent;

import { Divider } from "@mui/material";
import MuiInput from "@views/components/system/Forms/form_elements/mui_input";
import MuiSubmitButton from "@views/components/system/Forms/form_elements/mui_submit_button";
import { Col, Form } from "antd";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, _params, classes) => ({
  field: {
    width: "100%",
  },
  group: {
    margin: `${theme.spacing(1)} 0`,
  },
}));

const EditProject = (props) => {
  const { handle_close_modal } = props;
  const { classes } = useStyles();
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    console.log("values", values);
  };
  const handle_values_change = (val, values) => {
    console.log("handle_values_change", val, values);
  };

  return (
    <Form
      form={form}
      name={"projects"}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={handle_values_change}
      autoComplete="off"
      className="user_form ant-select-selector ant-select-selection-search"
    >
      <MuiInput
        field={"project"}
        label={"Name"}
        message={"Name is required"}
        is_required={true}
        class_name={classes.field}
        input_type={"text"}
        is_loading={false}
      />
      <Divider
        style={{
          marginTop: 20,
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 20,
        }}
      >
        <div
          style={{
            marginLeft: 5,
          }}
        >
          <MuiSubmitButton
            label={"Cancel"}
            action={"reset"}
            handle_close_modal={handle_close_modal}
            button_type={"default"}
          />
        </div>
        <div
          style={{
            marginLeft: 5,
          }}
        >
          <MuiSubmitButton
            label={"Save"}
            action={"submit"}
            button_type={"primary"}
          />
        </div>
      </div>
    </Form>
  );
};
export default EditProject;

import React, { useState } from "react";
import { Button, Form } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const MuiSubmitButton = (props) => {
  const {
    label,
    button_type,
    handle_close_modal,
    action,
    loading,
    style,
    is_disabled,
  } = props;

  return (
    <Form.Item className="no_margin_form_input">
      {action === "submit" ? (
        <Button type={button_type} htmlType={action ? action : "submit"}>
          {label}
        </Button>
      ) : (
        <Button
          type={button_type || "default"}
          onClick={handle_close_modal}
          htmlType={action ? action : "submit"}
          loading={loading}
          style={style || {}}
          disabled={is_disabled}
        >
          {label}
        </Button>
      )}
    </Form.Item>
  );
};
export default MuiSubmitButton;

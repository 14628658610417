import React, { useState } from "react";
import Hidden from "@mui/material/Hidden";
import useStyles from "../components/auth/user-jss";
import ChangePasswordForm from "@views/components/auth/change_password_form";

function ChangePassword() {
  const { classes } = useStyles();
  return (
    <div className={classes.rootFull}>
      <Hidden mdDown>
        <div className="login-background-container" />
      </Hidden>

      <div className={classes.sideFormWrap}>
        <ChangePasswordForm />
      </div>
    </div>
  );
}

export default ChangePassword;

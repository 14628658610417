import React, { useEffect, useRef, useState } from "react";
import PaperBlock from "@views/components/system/PaperBlock/PaperBlock";
import MuiDrawIo from "@views/components/system/Forms/form_elements/mui_draw_io";
import { Button, Form } from "antd";
import MuiInput from "@views/components/system/Forms/form_elements/mui_input";
import MuiSubmitButton from "@views/components/system/Forms/form_elements/mui_submit_button";
import { DrawIoEmbed } from "react-drawio";

const DrawIo = (props) => {
  const [form] = Form.useForm();
  const [xml, setXml] = useState("<mxfile><diagram/></mxfile>");
  const drawioRef = useRef(null);

  const handleGetXml = () => {
    const newXml = drawioRef.current?.getXml();
    setXml(newXml);
  };

  const onFinish = (values) => {
    console.log("onFinish", values);
  };
  return (
    <PaperBlock noMargin>
      <Form form={form} onFinish={onFinish}>
        {/* <div style={{ width: "100%", height: "600px" }}>
            <DrawIoEmbed
              ref={drawioRef}
              style={{ width: "100%", height: "300px" }}
              xml={xml}
              onExport={(data) => {
                console.log("onExport", data);
              }}
              onSave={(data) => {
                console.log("onSave", data);
              }}
            />
          </div> */}
        <MuiDrawIo />
        <Form.Item>
          <Button onClick={handleGetXml}>Get XML</Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </PaperBlock>
  );
};
export default DrawIo;

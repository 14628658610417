import { retrieveItem } from "@helpers/storage";
import { MethodType, status_codes } from "@helpers/service_call";
import { showToast } from "@helpers/toast";
import axios from "axios";

// const BASE_URL = "http://192.168.1.2:5000";
// const BASE_URL = "http://localhost:1500";
const BASE_URL = "https://dev.vertace.org/voffice";

const Token = retrieveItem("token");

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { Authorization: "Bearer " + Token, token: Token },
});

export const callService = function (
  url,
  type,
  isUrlType,
  data,
  urlData,
  callback,
  dispatch,
  keys
) {
  const Token = retrieveItem("token");

  switch (type) {
    case MethodType.GET:
      if (isUrlType) {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },
          method: MethodType.GET,
          url: url + urlData,
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.GET);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      } else {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },
          method: MethodType.GET,
          url: url,
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.GET);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      }
      break;
    case MethodType.POST:
      if (isUrlType) {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },

          method: MethodType.POST,
          url: url + urlData,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
              Token: Token,
            },
          },
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.POST);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      } else {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },

          method: MethodType.POST,
          url: url,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
              Token: Token,
            },
          },
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.POST);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      }
      break;
    case MethodType.PATCH:
      if (isUrlType) {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },

          method: MethodType.PATCH,
          url: url + urlData,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
              Token: Token,
            },
          },
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.PATCH);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      } else {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },

          method: MethodType.PATCH,
          url: url,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
              Token: Token,
            },
          },
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.PATCH);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      }
      break;
    case MethodType.PUT:
      if (isUrlType) {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },

          method: MethodType.PUT,
          url: url + urlData,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
              Token: Token,
            },
          },
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.PUT);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      } else {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },

          method: MethodType.PUT,
          url: url,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
              Token: Token,
            },
          },
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.PUT);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      }
      break;
    case MethodType.DELETE:
      if (isUrlType) {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },

          method: MethodType.DELETE,
          url: url + urlData,
          data: data,
          config: {
            headers: {
              "Content-Type": "application/json",
              Token: Token,
            },
          },
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.DELETE);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      } else {
        axiosInstance({
          headers: { Authorization: "Bearer " + Token, token: Token },

          method: MethodType.DELETE,
          url: url,
          data: data,
        })
          .then((res) => {
            if (handleResponse(res))
              callback(res.data, dispatch, keys, MethodType.DELETE);
          })
          .catch(function (error) {
            // handle error
            callback(error, dispatch, keys);
          })
          .finally(function () {
            // always executed
          });
      }
      break;
    default:
      break;
  }
};

function handleResponse(res) {
  var response = false;
  if (res.data) {
    if (res.data.statusCode === status_codes.Duplicate) {
      if (res.data.exception) {
        if (res.data.exception.includes("Posting")) {
          response = true;
        } else
          showToast({
            type: "error",
            message: res.data.exception
              ? res.data.exception
              : res.data.stringResult
              ? res.data.stringResult
              : "Duplicate value! Please try some other",
          });
      }
    } else if (res.data.statusCode === status_codes.InvalidData) {
      if (res.data.exception) {
        if (res.data.exception.includes("Old ")) {
          response = true;
        } else
          showToast({
            type: "error",
            message: res.data.exception
              ? res.data.exception
              : res.data.stringResult
              ? res.data.stringResult
              : "Please check your values! Something went wrong",
          });
      }
    } else if (res.data.statusCode === status_codes.Forbidden)
      showToast({
        type: "error",
        message: res.data.exception
          ? res.data.exception
          : res.data.stringResult
          ? res.data.stringResult
          : "Server rejected our request",
      });
    else if (res.data.statusCode === status_codes.NotFound) {
      if (res.data.exception) {
        if (
          res.data.exception.includes("OTP") ||
          res.data.exception.includes("Officer")
        ) {
          response = true;
        } else {
          showToast({
            type: "error",
            message: res.data.exception
              ? res.data.exception
              : res.data.stringResult
              ? res.data.stringResult
              : "Your request is not found",
          });
        }
      }
    } else if (res.data.statusCode === status_codes.Unauthorized) {
      if (res.config.url.includes("signin")) {
        response = true;
      } else {
        showToast({
          type: "error",
          message: res.data.exception
            ? res.data.exception
            : res.data.stringResult
            ? res.data.stringResult
            : "Your session expired. Please login again",
        });

        var login = sessionStorage.getItem("login");
        sessionStorage.clear();
        sessionStorage.setItem("login", login);
        window.location.replace("");
      }
    } else if (res.data.statusCode === status_codes.Error)
      showToast({
        type: "error",
        message: res.data.exception
          ? res.data.exception
          : res.data.stringResult
          ? res.data.stringResult
          : "Something went wrong",
      });
    else response = true;
  } else response = true;

  return response;
}

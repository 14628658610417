import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { makeStyles } from "tss-react/mui";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles()((theme) => ({
  demo: {
    height: 240,
  },
  divider: {
    margin: `${theme.spacing(3)} 0`,
  },
  picker: {
    margin: `${theme.spacing(3)} 5px`,
    width: "100%",
  },
}));

function DateInput() {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const { classes } = useStyles();

  return (
    <div className={classes.picker}>
      <TextField
        id="date"
        label="Birthday"
        type="date"
        variant="standard"
        defaultValue="2017-05-24"
        sx={{ width: "100%" }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}

export default DateInput;

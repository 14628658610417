import { navigate, navigateWithProps } from "@helpers/navigator";
import { Input, MenuItem, Select, TextField } from "@mui/material";
import MuiDatePicker from "@views/components/system/Forms/form_elements/mui_date_picker";
import MuiSearch from "@views/components/system/Forms/form_elements/mui_search";
import MuiSelect from "@views/components/system/Forms/form_elements/mui_select";
import MuiSubmitButton from "@views/components/system/Forms/form_elements/mui_submit_button";
import MPagination from "@views/components/system/MTable/pagination";
import PaperBlock from "@views/components/system/PaperBlock/PaperBlock";
import ReimbursementFilters from "@views/components/ui/reimbursement/reimbursement_filters";
import { ROUTES } from "@views/routes/my_routes";
import { Table } from "antd";
import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";

const Reimbursement = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selected_employee, set_selected_employee] = useState("");
  const [selected_type, set_selected_type] = useState("");
  const [selected_project, set_selected_project] = useState("");
  const [selected_status, set_selected_status] = useState("");
  const [disable_buttons, set_disable_buttons] = useState(true);

  const columns = [
    {
      title: "Ref No",
      dataIndex: "ref_no",
      align: "center",
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
    },
    {
      title: "Project",
      dataIndex: "project",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "",
      align: "center",
      render: (record) => {
        let user_id = record?.ref_no;
        return (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              // navigate(`${ROUTES.REIMBURSEMENT_EMPLOYEE_DETAILS}/${user_id}`);
              navigateWithProps(
                `${ROUTES.REIMBURSEMENT_EMPLOYEE_DETAILS}`,
                user_id
              );
            }}
          >
            <AiFillEye />
          </div>
        );
      },
    },
  ];
  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      ref_no: `Edward King ${i}`,
      employee_name: i + 20,
      date: `London, Park Lane no. ${i}`,
      project: `project,  ${i}`,
      amount: `amount,  ${i}`,
      status: `status,  ${i}`,
    });
  }
  const onSelectChange = (keys, values) => {
    console.log("disable_buttons: ", disable_buttons);
    setSelectedRowKeys(keys);
    if (keys?.length) {
      set_disable_buttons(false);
    } else {
      set_disable_buttons(true);
    }
  };

  const employee_options = [
    {
      id: "00001",
      name: "Ajithkumar V",
    },
    {
      id: "00002",
      name: "Employee 2",
    },
    {
      id: "00003",
      name: "Employee 3",
    },
  ];
  const project_list = [
    {
      id: "p00001",
      name: "Project-1",
    },
    {
      id: "p00002",
      name: "Project-2",
    },
    {
      id: "p00003",
      name: "Project-3",
    },
  ];
  const status_list = [
    {
      id: "st00001",
      name: "Status-1",
    },
    {
      id: "st00002",
      name: "Status-2",
    },
    {
      id: "st00003",
      name: "Status-3",
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handle_employee_change = (e) => {
    let selected = e.target.value;
    set_selected_employee(selected);
  };
  const handle_type_change = (e) => {
    let selected = e.target.value;
    set_selected_type(selected);
  };
  const handle_project_change = (e) => {
    let selected = e.target.value;
    set_selected_project(selected);
  };
  const handle_status_change = (e) => {
    let selected = e.target.value;
    set_selected_status(selected);
  };
  console.log("selectedRowKeys?.length", selectedRowKeys?.length);
  return (
    <PaperBlock noMargin>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <MuiSearch label={"Search"} width={"100%"} />
      </div>
      <ReimbursementFilters
        handle_employee_change={handle_employee_change}
        handle_project_change={handle_project_change}
        handle_status_change={handle_status_change}
        handle_type_change={handle_type_change}
        selected_type={selected_type}
        selected_employee={selected_employee}
        selected_status={selected_status}
        employee_options={employee_options}
        selected_project={selected_project}
        project_list={project_list}
        status_list={status_list}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 5,
          marginTop: 20,
        }}
      >
        <MuiSubmitButton
          style={{
            backgroundColor: disable_buttons ? "#999" : "#009045",
            color: "#fff",
            width: 100,
          }}
          label={"Approve"}
          button_type={"primary"}
          is_disabled={disable_buttons}
        />

        <MuiSubmitButton
          style={{
            backgroundColor: disable_buttons ? "#999" : "#D25555",
            color: "#fff",
            width: 100,
          }}
          label={"Reject"}
          button_type={"primary"}
          is_disabled={disable_buttons}
        />
      </div>

      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        className="custom-table"
        pagination={false}
      />
      {/* <MPagination
          handle_change_page={handle_change_page}
          page_number={page_number}
          current={current}
          page_limit={page_limit}
          handle_row_change={handle_row_change}
          total_count={subscription_plans_pagination?.total_count}
          classes={classes}
        /> */}
    </PaperBlock>
  );
};

export default Reimbursement;

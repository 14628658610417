import { dummy_array } from "@helpers/constants";
import { MethodType } from "@helpers/service_call";
import { Divider, Typography } from "@mui/material";
import {
  dynamicRequest,
  get_all_project,
  useDynamicSelector,
  work_item,
} from "@services/redux";
import MuiDatePicker from "@views/components/system/Forms/form_elements/mui_date_picker";
import MuiInput from "@views/components/system/Forms/form_elements/mui_input";
import MuiSelect from "@views/components/system/Forms/form_elements/mui_select";
import MuiSubmitButton from "@views/components/system/Forms/form_elements/mui_submit_button";
import { Col, Form, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";
import TimeSheetProjectList from "./timesheet_project_list";
import MuiTimePicker from "@views/components/system/Forms/form_elements/mui_time_picker";
import TimeSheetTaskTime from "./timesheet_task_time";

const useStyles = makeStyles()((theme, _params, classes) => ({
  field: {
    width: "100%",
  },
  group: {
    margin: `${theme.spacing(1)} 0`,
  },
}));

const TimeSheetDetails = (props) => {
  const { handle_close_modal, date_range, get_project_work_item_entries } =
    props;
  const { classes } = useStyles();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [selected_project_value, set_selected_project_value] = useState("");
  const [selected_epic_value, set_selected_epic_value] = useState("");
  const [selected_feature_value, set_selected_feature_value] = useState("");
  const [selected_user_story_value, set_selected_user_story_value] =
    useState("");
  const [selected_task_value, set_selected_task_value] = useState("");

  const [filtered_epic, set_filtered_epic] = useState([]);
  const [filtered_feature, set_filtered_feature] = useState([]);
  const [filtered_user_story, set_filtered_user_story] = useState([]);
  const [filtered_task, set_filtered_task] = useState([]);

  const { items: work_items, loading: work_items_loading } =
    useDynamicSelector("work_item");

  const { items: get_all_projects_items, loading: get_all_projects_loading } =
    useDynamicSelector("get_all_projects");

  useEffect(() => {
    get_all_project_list();
  }, []);

  useEffect(() => {
    if (selected_project_value && work_items?.length) {
      let temp = work_items?.filter((x) => x.story_points > 0);

      set_filtered_epic(temp);
    }
    if (selected_epic_value && work_items?.length) {
      let temp = work_items?.find((x) => x.id === selected_epic_value);
      let _temp = temp?.children?.filter((x) => x.story_points > 0);

      set_filtered_feature(_temp);
    }
    if (selected_feature_value && work_items?.length) {
      let temp = filtered_feature?.find((x) => x.id === selected_feature_value);
      let _temp = temp?.children?.filter((x) => x.story_points > 0);

      set_filtered_user_story(_temp);
    }
    if (selected_user_story_value && work_items?.length) {
      let temp = filtered_user_story?.find(
        (x) => x.id === selected_user_story_value
      );
      // let _temp = temp?.children?.filter((x) => x.story_points > 0);

      set_filtered_task(temp?.children);
    }
  }, [
    selected_epic_value,
    selected_feature_value,
    selected_user_story_value,
    selected_project_value,
    work_items,
  ]);

  const handleSubmit = (values) => {
    console.log("values", values);
  };
  const handle_values_change = (val, values) => {
    console.log("handle_values_change", val, values);
  };

  const handle_project_change = (id, name) => {
    let selected_value = id;
    set_selected_epic_value("");
    set_selected_feature_value("");
    set_selected_user_story_value("");
    set_selected_task_value("");
    set_filtered_epic([]);
    get_project_work_item(selected_value);

    set_selected_project_value(selected_value);
  };

  const handle_epic_change = (id, name) => {
    let selected_value = id;

    set_selected_epic_value(selected_value);
    set_selected_feature_value("");
    set_selected_user_story_value("");
    set_selected_task_value("");
    form.setFieldsValue({ epic: selected_value });
  };
  const handle_feature_change = (id, name) => {
    let selected_value = id;

    set_selected_feature_value(selected_value);
    set_selected_user_story_value("");
    set_selected_task_value("");
    form.setFieldsValue({ feature: selected_value });
  };
  const handle_user_story_change = (id, name) => {
    let selected_value = id;

    set_selected_user_story_value(selected_value);
    set_selected_task_value("");
    form.setFieldsValue({ user_story: selected_value });
  };

  const handle_task_change = (id, name) => {
    let selected_value = id;

    set_selected_task_value(selected_value);
    form.setFieldsValue({ task: selected_value });
  };

  const get_project_work_item = (_id) => {
    let keys = [{ key: "work_item", loading: true }];
    let query = work_item;
    let variables = {
      project_id: _id,
    };
    dispatch(dynamicRequest(keys, query, variables, MethodType.POST));
  };
  const get_all_project_list = () => {
    let keys = [{ key: "get_all_projects", loading: true }];
    let query = get_all_project;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables, MethodType.POST));
  };
  return (
    // <Form
    //   form={form}
    //   name={"projects"}
    //   layout={"vertical"}
    //   onFinish={handleSubmit}
    //   onValuesChange={handle_values_change}
    //   autoComplete="off"
    //   className="user_form ant-select-selector ant-select-selection-search"
    // >
    //   <Typography
    //     sx={{
    //       fontWeight: "bold",
    //     }}
    //   >
    //     {moment(date_range?.start).format("DD/MM/YYYY")}
    //   </Typography>
    //   <Row gutter={[16, 16]}>
    //     <Col xs={24} sm={!selected_project_value ? 24 : 12}>
    //       <MuiSelect
    //         field={"project"}
    //         label={"Project"}
    //         message={"Select is required"}
    //         is_required={false}
    //         options={dummy_array}
    //         selected_value={selected_project_value}
    //         handle_select_change={handle_project_change}
    //         labelField={"name"}
    //         valueField={"id"}
    //         is_loading={false}
    //         form={form}
    //       />
    //     </Col>
    //     {selected_project_value && filtered_epic?.length > 0 && (
    //       <Col xs={24} sm={12}>
    //         <MuiSelect
    //           field={"epic"}
    //           label={"Epic"}
    //           message={"Epic is required"}
    //           is_required={false}
    //           options={filtered_epic}
    //           selected_value={selected_epic_value}
    //           handle_select_change={handle_epic_change}
    //           labelField={"title"}
    //           valueField={"id"}
    //           is_loading={work_items_loading}
    //           form={form}
    //         />
    //       </Col>
    //     )}
    //     {selected_epic_value && filtered_feature?.length > 0 && (
    //       <Col xs={24} sm={12}>
    //         <MuiSelect
    //           field={"feature"}
    //           label={"Feature"}
    //           message={"Feature is required"}
    //           is_required={false}
    //           options={filtered_feature}
    //           selected_value={selected_feature_value}
    //           handle_select_change={handle_feature_change}
    //           labelField={"title"}
    //           valueField={"id"}
    //           is_loading={work_items_loading}
    //           form={form}
    //         />
    //       </Col>
    //     )}
    //     {selected_feature_value && filtered_user_story?.length > 0 && (
    //       <Col xs={24} sm={12}>
    //         <MuiSelect
    //           field={"user_story"}
    //           label={"User Story"}
    //           message={"User Story is required"}
    //           is_required={false}
    //           options={filtered_user_story}
    //           selected_value={selected_user_story_value}
    //           handle_select_change={handle_user_story_change}
    //           labelField={"title"}
    //           valueField={"id"}
    //           is_loading={work_items_loading}
    //           form={form}
    //         />
    //       </Col>
    //     )}
    //     {selected_user_story_value && filtered_task?.length > 0 && (
    //       <Col xs={24} sm={12}>
    //         <MuiSelect
    //           field={"task"}
    //           label={"Task"}
    //           message={"Task is required"}
    //           is_required={false}
    //           options={filtered_task}
    //           selected_value={selected_task_value}
    //           handle_select_change={handle_task_change}
    //           labelField={"title"}
    //           valueField={"id"}
    //           is_loading={work_items_loading}
    //           form={form}
    //         />
    //       </Col>
    //     )}
    //   </Row>
    //   <Divider
    //     style={{
    //       marginTop: 20,
    //     }}
    //   />
    //   <div
    //     style={{
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "flex-end",
    //       marginTop: 20,
    //     }}
    //   >
    //     <div
    //       style={{
    //         marginLeft: 5,
    //       }}
    //     >
    //       <MuiSubmitButton
    //         label={"Cancel"}
    //         action={"reset"}
    //         handle_close_modal={handle_close_modal}
    //         button_type={"default"}
    //       />
    //     </div>
    //     <div
    //       style={{
    //         marginLeft: 5,
    //       }}
    //     >
    //       <MuiSubmitButton
    //         label={"Save"}
    //         action={"submit"}
    //         button_type={"primary"}
    //       />
    //     </div>
    //   </div>
    // </Form>

    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <TimeSheetProjectList
        handle_page_open={handle_project_change}
        items={get_all_projects_items}
        loading={get_all_projects_loading}
        title={"Projects"}
        selected_value={selected_project_value}
        width={"15%"}
      />
      {selected_project_value && (
        <TimeSheetProjectList
          handle_page_open={handle_epic_change}
          items={filtered_epic}
          loading={work_items_loading}
          title={"Epics"}
          selected_value={selected_epic_value}
          width={"15%"}
        />
      )}
      {selected_epic_value && filtered_feature?.length > 0 && (
        <TimeSheetProjectList
          handle_page_open={handle_feature_change}
          items={filtered_feature}
          loading={work_items_loading}
          title={"Features"}
          selected_value={selected_feature_value}
          width={"15%"}
        />
      )}
      {selected_epic_value &&
        selected_feature_value &&
        filtered_user_story?.length > 0 && (
          <TimeSheetProjectList
            handle_page_open={handle_user_story_change}
            items={filtered_user_story}
            loading={work_items_loading}
            title={"User Stories"}
            selected_value={selected_user_story_value}
            width={"20%"}
          />
        )}
      {console.log("filtered_task", filtered_task)}
      {selected_epic_value &&
        selected_feature_value &&
        selected_user_story_value &&
        filtered_task?.length > 0 && (
          <TimeSheetProjectList
            handle_page_open={handle_task_change}
            items={filtered_task}
            loading={work_items_loading}
            title={"Task"}
            width={"15%"}
            selected_value={selected_task_value}
          />
        )}
      {selected_epic_value &&
        selected_feature_value &&
        selected_user_story_value &&
        selected_task_value && (
          <TimeSheetTaskTime
            title={"Select Time Period"}
            selected_task_value={selected_task_value}
            width={"20%"}
            date_range={date_range}
            handle_close_modal={handle_close_modal}
            get_project_work_item_entries={get_project_work_item_entries}
          />
        )}
    </div>
  );
};
export default TimeSheetDetails;

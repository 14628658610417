import React from "react";
import { Form } from "antd";
import { IconButton, InputBase, Paper } from "@mui/material";
import MuiSkeleton from "../../Loading/mui_skeleton";
import SearchIcon from "@mui/icons-material/Search";

const MuiSearch = (props) => {
  const {
    field,
    label,
    message,
    is_required,
    width,
    is_loading,
    handle_change,
  } = props;
  return (
    <Form.Item
      name={field}
      rules={[
        {
          required: is_required || false,
          message: message || "",
        },
      ]}
      className="no_margin_form_input"
    >
      {is_loading ? (
        <MuiSkeleton
          variant_shape={"rectangle"}
          width={"100%"}
          height={60}
          animation_type={"wave"} //"wave" or "pulse"
          color={"#f1f1f1"}
          border_radius={10}
        />
      ) : (
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: width,
            borderRadius: 10,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={label}
            inputProps={{ "aria-label": label }}
            onChange={handle_change}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      )}
    </Form.Item>
  );
};
export default MuiSearch;

import { MethodType } from "@helpers/service_call";
import { CircularProgress, Divider, Skeleton, Typography } from "@mui/material";
import {
  create_work_item,
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import MuiSubmitButton from "@views/components/system/Forms/form_elements/mui_submit_button";
import MuiTimePicker from "@views/components/system/Forms/form_elements/mui_time_picker";
import { Form } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillClockCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";

const TimeSheetTaskTime = (props) => {
  const {
    selected_task_value,
    items,
    loading,
    title,
    date_range,
    width,
    handle_close_modal,
    get_project_work_item_entries,
  } = props;

  const dispatch = useDispatch();

  const [start_time, set_start_time] = useState(null);
  const [end_time, set_end_time] = useState(null);

  const {
    status: create_work_item_status,
    loading: create_work_item_loading,
    error: create_work_item_error,
  } = useDynamicSelector("create_work_item");

  useEffect(() => {
    if (date_range) {
      set_start_time(date_range?.start_date);
      set_end_time(date_range?.end_date);
    }
  }, [date_range]);
  useEffect(() => {
    if (create_work_item_status === "success") {
      dispatch(dynamicClear("create_work_item"));
      toast.success("Work Item Created");
      get_project_work_item_entries();
      handle_close_modal();
    } else if (create_work_item_error) {
      toast.error(create_work_item_error?.message);

      dispatch(dynamicClear("create_work_item"));
    }
  }, [create_work_item_status, create_work_item_error]);
  const handle_submit = () => {
    const fromTime = new Date(start_time);
    const toTime = new Date(end_time);

    if (toTime < fromTime) {
      toast.error("End time should not be less than start time");
    } else {
      let keys = [{ key: "create_work_item", loading: true }];
      let query = create_work_item;
      let variables = {
        workitem_id: selected_task_value,
        from: start_time,
        to: end_time,
      };
      dispatch(dynamicRequest(keys, query, variables, MethodType.PUT));
    }
  };
  const handleStartTimeChange = (_time) => {
    set_start_time(_time);
  };
  const handleEndTimeChange = (_time) => {
    set_end_time(_time);
  };

  return (
    <div
      style={{
        width: width,
        borderRadius: 5,
        borderColor: "#111",
        borderWidth: 2,
        backgroundColor: "#e8e3e3 ",

        // backgroundImage: `repeating-linear-gradient(293deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),repeating-linear-gradient(43deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),repeating-linear-gradient(0deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),linear-gradient(90deg, rgb(253,253,253),rgb(253,253,253))`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AiFillClockCircle />
        <Typography
          style={{
            fontWeight: "bolder",
            textAlign: "center",
            padding: 2,
            fontSize: 16,
          }}
        >
          {title}
        </Typography>
      </div>

      <Divider />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Form onFinish={handle_submit}>
          <div
            style={{
              width: "100%",
            }}
          >
            {/* <Typography
            style={{
              fontSize: 12,
            }}
          >
            Start Time
          </Typography> */}
            <MuiTimePicker
              field={"start_time"}
              set_time={set_start_time}
              time={start_time}
              onChange={handleStartTimeChange}
              label={"Start Time"}
            />
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            {/* <Typography
            style={{
              fontSize: 12,
            }}
          >
            End Time
          </Typography> */}
            <MuiTimePicker
              field={"end_time"}
              set_time={set_end_time}
              time={end_time}
              onChange={handleEndTimeChange}
              label={"End Time"}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <MuiSubmitButton
              label={"Save"}
              action={"submit"}
              button_type={"primary"}
              loading={create_work_item_loading}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
export default TimeSheetTaskTime;

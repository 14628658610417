import React, { useEffect, useState } from "react";
import { DayPilot, DayPilotScheduler } from "daypilot-pro-react";
import MuiSubmitButton from "@views/components/system/Forms/form_elements/mui_submit_button";
import { Button } from "@mui/material";
import Modal from "@views/components/system/Modal/modal";
import TimeSheetDetails from "@views/components/ui/timesheet/timesheet_details";
import {
  ceo_work_item_entries,
  delete_time_sheet_task,
  dynamicClear,
  dynamicRequest,
  get_all_project,
  useDynamicSelector,
  work_item,
  work_item_entries,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { MethodType } from "@helpers/service_call";
import toast from "react-hot-toast";
import moment from "moment";

const TimeSheet = () => {
  const schedulerRef = React.createRef();
  const dispatch = useDispatch();
  const getScheduler = () => schedulerRef.current.control;

  const [showBusinessOnly, setShowBusinessOnly] = useState(false);
  const [showDailyTotals, setShowDailyTotals] = useState(false);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [date_range, set_date_range] = useState({
    start_date: "",
    end_date: "",
  });

  const { items: work_item_entries_items } =
    useDynamicSelector("work_item_entries");
  const { items: work_items_for_ceo_items } =
    useDynamicSelector("work_items_for_ceo");
  const {
    status: delete_task_status,
    error: delete_task_error,
    loading: delete_task_loading,
  } = useDynamicSelector("delete_task");

  const projects = [
    { id: 1, name: "Project A", color: "#38761d" },
    { id: 2, name: "Project B", color: "#0d8ecf" },
    { id: 3, name: "Project C", color: "#f1c232" },
  ];

  const currentWeekMonday = DayPilot.Date.today()
    .firstDayOfWeek(DayPilot.Locale.US)
    .addDays(1);
  const [weeksToSubtract, setWeeksToSubtract] = useState(0);

  const [config, setConfig] = useState({
    locale: "en-us",
    viewType: "Days",
    showNonBusiness: false,
    businessWeekends: true,
    businessBeginsHour: 9,
    businessEndsHour: 24,
    rowHeaderColumns: [{ title: "Date" }, { title: "Total Hours" }],
    cellWidthSpec: "Auto",
    timeHeaders: [{ groupBy: "Hour" }, { groupBy: "Cell", format: "mm" }],
    scale: "CellDuration",
    cellDuration: 30,
    days: 7,
    // startDate: new Date(),
    startDate: currentWeekMonday.toDate(),

    heightSpec: "Max",
    height: 300,
    eventDeleteHandling: "Update",
    eventClickHandling: "Disabled",

    onEventDelete: function (args) {
      // this.onRowClicked(args);
      delete_task(args.e.cache.id);
    },
    onTimeRangeSelected: (args) => {
      handle_open_modal(args.start.value, args.end.value);

      // this.display = "block";
      // this.title = "Add";
      // this.resource = args.resource;
      // this.tableStartTime = args.start;
      let time,
        starttime = args.start.value.split("T")[1].split(":");
      if (Number(starttime[0]) > 12) {
        starttime[0] = (Number(starttime[0]) - 12).toString();
        time = starttime[0] + ":" + starttime[1] + " " + "PM";
      } else if (Number(starttime[0]) === 12)
        time = starttime[0] + ":" + starttime[1] + " " + "PM";
      else time = starttime[0] + ":" + starttime[1] + " " + "AM";
      // let startTimeIndex = this.startTime.findIndex((x) => x.name === time);
      // this.addTaskForm = this.fb.group({
      //   task: ["", Validators.required],
      //   StartTime: [this.startTime[startTimeIndex].name],
      //   EndTime: ["", Validators.required],
      // });
    },
    onBeforeRowHeaderRender: (args) => {
      const duration = args.row.events.totalDuration();
      args.row.columns[0].html = args.row.start.toString(
        " d/M/yyyy",
        config.locale
      );
      if (duration.totalSeconds() === 0) {
        return;
      }
      let str = duration.toString("H:mm");
      if (duration.totalDays() >= 1) {
        str = Math.floor(duration.totalHours()) + ":" + duration.toString("mm");
      }
      args.row.columns[1].html = str + " hours";
    },
    onBeforeEventRender: (args) => {
      const duration = new DayPilot.Duration(args.data.start, args.data.end);
      args.data.areas = [
        {
          right: 2,
          top: 0,
          bottom: 0,
          width: 30,
          html: duration.toString("h:mm"),
          style: "display: flex; align-items: center",
        },
      ];
    },
    onRowClicked: (args) => {
      if (args && args.e && args.e.cache && args.e.cache.id) {
        // this.deleteTaskId = args.e.cache.id;
        // this.deleteTask();
        //this.deleteDisplay = "block";
      }
    },
  });

  useEffect(() => {
    if (delete_task_status === "success") {
      toast.success("Task Removed");
      get_project_work_item_entries();
      dispatch(dynamicClear("delete_task"));
    } else if (delete_task_error) {
      toast.error(delete_task_error?.message);
      dispatch(dynamicClear("delete_task"));
    }
  }, [delete_task_status, delete_task_error]);

  useEffect(() => {
    const headerColor = "#056cb4";
    const textColor = "white";
    const header = document.getElementsByClassName(
      "scheduler_default_corner"
    )[0];
    const text1 =
      '<div style="position: absolute; padding: 2px; top: 0px; left: 1px; background-color: rgb(255, 102, 0); color: white;">DEMO</div>';
    const text2 =
      '<div style="position: absolute; padding: 5px; top: 0px; left: 15px;font-weight: bold; color: white;">Weekly Timesheet</div>';
    if (header) {
      header.innerHTML = header.innerHTML.replace(text1, text2);
      header.innerText = "Date\nDay\nWeeklyTimesheet";
    }

    const corner = document.getElementsByClassName("scheduler_default_corner");
    for (let i = 0; i < corner.length; i++) {
      const cornerStyle = corner[i].getAttribute("style");
      corner[i].setAttribute(
        "style",
        cornerStyle + "background:" + headerColor + "; color:" + textColor + ";"
      );
    }
    const timeheadercol = document.getElementsByClassName(
      "scheduler_default_timeheadercol"
    );
    for (let i = 0; i < timeheadercol.length; i++) {
      const timeheadercolStyle = timeheadercol[i].getAttribute("style");
      timeheadercol[i].setAttribute(
        "style",
        timeheadercolStyle +
          "background:" +
          headerColor +
          "; color:" +
          textColor +
          ";"
      );
    }

    const timeheadergroup = document.getElementsByClassName(
      "scheduler_default_timeheadergroup"
    );
    for (let i = 0; i < timeheadergroup.length; i++) {
      const timeheadergroupStyle = timeheadergroup[i].getAttribute("style");
      timeheadergroup[i].setAttribute(
        "style",
        timeheadergroupStyle +
          "background:" +
          headerColor +
          "; color:" +
          textColor +
          ";"
      );
    }
  }, []);

  useEffect(() => {
    if (work_item_entries_items?.length) {
      const events = work_item_entries_items?.map((x) => ({
        id: x.id,
        text: x.workitem.name,
        start: x.from,
        end: x.to,
      }));
      getScheduler().update({
        events,
        scrollTo: DayPilot.Date.today().firstDayOfMonth().addHours(9),
      });
    }
  }, [work_item_entries_items]);

  useEffect(() => {
    get_project_work_item_entries();
    get_all_work_items_ceo();
  }, []);

  const handlePreviousWeek = () => {
    // getScheduler().startDate = DayPilot.Date.today()
    //   .firstDayOfWeek()
    //   .addDays(1)
    //   .addDays(-7);
    // getScheduler().update();
    // console.log(
    //   "handlePreviousWeek",
    //   DayPilot.Date.today().firstDayOfWeek().addDays(1).addDays(-7)
    // );
    // setConfig({
    //   startDate: DayPilot.Date.today().firstDayOfWeek().addDays(1).addDays(-7),
    // });
    const newStartDate = DayPilot.Date.today()
      .firstDayOfWeek()
      .addDays(1)
      .addDays(-7 * (weeksToSubtract + 1));
    setConfig({ ...config, startDate: newStartDate });
    getScheduler().startDate = newStartDate;
    getScheduler().update();
    setWeeksToSubtract(weeksToSubtract + 1);
  };

  const handleNextWeek = () => {
    getScheduler().startDate = DayPilot.Date.today()
      .firstDayOfWeek()
      .addDays(1)
      .addDays(7);
    getScheduler().update();
    setConfig({
      startDate: DayPilot.Date.today().firstDayOfWeek().addDays(1).addDays(7),
    });
  };

  const handleCurrentWeek = () => {
    setWeeksToSubtract(0);
    getScheduler().startDate = DayPilot.Date.today()
      .firstDayOfWeek()
      .addDays(1);
    getScheduler().update();
    setConfig({ startDate: DayPilot.Date.today().firstDayOfWeek().addDays(1) });
  };
  const handle_open_modal = (start, end) => {
    set_is_modal_open(true);
    set_date_range({ start_date: start, end_date: end });
  };
  const handle_close_modal = () => {
    set_is_modal_open(false);
  };

  const get_project_work_item_entries = () => {
    let keys = [{ key: "work_item_entries", loading: true }];
    let query = work_item_entries;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables, MethodType.POST));
  };
  const get_all_work_items_ceo = () => {
    let keys = [{ key: "work_items_for_ceo", loading: true }];
    let query = ceo_work_item_entries;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables, MethodType.POST));
  };

  const delete_task = (id) => {
    let keys = [{ key: "delete_task", loading: true }];
    let query = `${delete_time_sheet_task}/${id}`;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables, MethodType.DELETE));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginTop: 10,
          marginBottom: 10,
          gap: 10,
        }}
      >
        <Button
          style={{
            backgroundColor: "#056cb4",
          }}
          variant="contained"
          type="button"
          onClick={handlePreviousWeek}
        >
          Previous Week
        </Button>
        <Button
          style={{
            backgroundColor: "#056cb4",
          }}
          variant="contained"
          type="button"
          onClick={handleCurrentWeek}
        >
          Current Week
        </Button>
        <Button
          style={{
            backgroundColor: "#056cb4",
          }}
          variant="contained"
          type="button"
          onClick={handleNextWeek}
        >
          Next Week
        </Button>
      </div>

      <DayPilotScheduler {...config} ref={schedulerRef} />
      <Modal
        is_open={is_modal_open}
        handleClose={handle_close_modal}
        scroll={"body"}
        content={
          <TimeSheetDetails
            date_range={date_range}
            handle_close_modal={handle_close_modal}
            get_project_work_item_entries={get_project_work_item_entries}
          />
        }
        title={`Add Task (${moment(date_range?.start_date).format(
          "dddd"
        )} ${moment(date_range?.start_date).format("hh:mm a")} - ${moment(
          date_range?.end_date
        ).format("hh:mm a")})`}
        footer={false}
        full_width={true}
        width={"xl"}
      />
    </div>
  );
};

export default TimeSheet;

import { AiFillMoneyCollect } from "react-icons/ai";

export const languages = ["en"];
export const ProjectName = "Vertace Projects";
export const defaultPageLimit = 999999999999;
export const defaultPageNumber = 1;
export const page_count = 12;
export const operator_matches_page_count = 1;
export const DEFAULT_COUNTRY_CODE = "in";
export const DEFAULT_STATE_CODE = "tn";

export const astrology_charts = [
  { path: "D1", value: "Brith Chart" },
  { path: "D9", value: "Navamansha Chart" },
];

export const GOOGLE_API_KEY = "AIzaSyCVY8hRJ-X4VVYVuDECrRQynxwvuk6Kgow";
export const LOCATION_PROVIDER = "google";

export const ASTROLOGY_USER_ID = "620110";
export const ASTROLOGY_API_KEY = "cb343e1cba3ecc769520b97ea12cfdc9";
export const ASTROLOGY_URL =
  "https://json.astrologyapi.com/v1/horo_chart_image/";

export const ASTROLOGY_BASE_URL =
  "https://json.astrologyapi.com/v1/horo_chart_image/";

export const IMAGE_URL = "https://cdn.standardmatrimony.com/";

export const PROMO_URL = "https://stdmry.page.link/";

export const HOROSCOPE_URL = "https://horoscope.standardmatrimony.com/";

export const Data = [
  {
    name: "mano",
    gender: "m",
    dob: "1995-10-02T00:00:00.000Z",
    country: "IN",
    state: "TN",
    city: "chennai",
  },
];

export const brand = {
  name: "Vertace Projects",
  desc: "Vertace Projects",
  prefix: "Vertace",
  footerText: "Vertace",
  logoText: "Vertace",
};
const images = [
  // 1
  "http://via.placeholder.com/1050x700/2196F3/FFFFFF/",
  "http://via.placeholder.com/1050x700/3F51B5/FFFFFF/",
  "http://via.placeholder.com/1050x700/00BCD4/FFFFFF/",
  "http://via.placeholder.com/1050x700/009688/FFFFFF/",
  "http://via.placeholder.com/1050x700/01579B/FFFFFF/",
  "http://via.placeholder.com/1050x700/0097A7/FFFFFF/",
  "http://via.placeholder.com/1050x700/43A047/FFFFFF/",
  "http://via.placeholder.com/1050x700/558B2F/FFFFFF/",
  "http://via.placeholder.com/1050x700/1DE9B6/767676/",
  "http://via.placeholder.com/1050x700/00E5FF/767676/",
  "http://via.placeholder.com/1050x700/C6FF00/767676/",
  // 2
  "http://via.placeholder.com/1050x700/D4E157/767676/",
  "http://via.placeholder.com/1050x700/F8BBD0/767676/",
  "http://via.placeholder.com/1050x700/FFCA28/767676/",
  "http://via.placeholder.com/1050x700/CFD8DC/767676/",
  "http://via.placeholder.com/1050x700/673AB7/FFFFFF/",
  "http://via.placeholder.com/1050x700/EF5350/FFFFFF/",
  "http://via.placeholder.com/1050x700/1E88E5/FFFFFF/",
  "http://via.placeholder.com/1050x700/3D5AFE/FFFFFF/",
  "http://via.placeholder.com/1050x700/EF6C00/FFFFFF/",
  "http://via.placeholder.com/1050x700/795548/FFFFFF/",
  // 3
  "http://via.placeholder.com/1050x700/FFE57F/767676/",
  "http://via.placeholder.com/1050x700/DCEDC8/767676/",
  "http://via.placeholder.com/1050x700/E1BEE7/767676/",
  "http://via.placeholder.com/1050x700/BBDEFB/767676/",
  "http://via.placeholder.com/1050x700/388E3C/FFFFFF/",
  "http://via.placeholder.com/1050x700/651FFF/FFFFFF/",
  "http://via.placeholder.com/1050x700/757575/FFFFFF/",
  "http://via.placeholder.com/1050x700/E91E63/FFFFFF/",
  "http://via.placeholder.com/1050x700/607D8B/FFFFFF/",
  "http://via.placeholder.com/1050x700/AA00FF/FFFFFF/",
  // 4
  "http://via.placeholder.com/1050x700/827717/FFFFFF/",
  "http://via.placeholder.com/1050x700/E64A19/FFFFFF/",
  "http://via.placeholder.com/1050x700/C2185B/FFFFFF/",
  "http://via.placeholder.com/1050x700/AA00FF/FFFFFF/",
  "http://via.placeholder.com/1050x700/1976D2/FFFFFF/",
  "http://via.placeholder.com/1050x700/D1C4E9/767676/",
  "http://via.placeholder.com/1050x700/81D4FA/767676/",
  "http://via.placeholder.com/1050x700/E0F2F1/767676/",
  "http://via.placeholder.com/1050x700/E6EE9C/767676/",
  "http://via.placeholder.com/1050x700/FFEB3B/767676/",
  // 5
  "http://via.placeholder.com/1050x700/E040FB/FFFFFF/",
  "http://via.placeholder.com/1050x700/C62828/FFFFFF/",
  "http://via.placeholder.com/1050x700/AD1457/FFFFFF/",
  "http://via.placeholder.com/1050x700/673AB7/FFFFFF/",
  "http://via.placeholder.com/1050x700/651FFF/FFFFFF/",
  "http://via.placeholder.com/1050x700/00BFA5/FFFFFF/",
  "http://via.placeholder.com/1050x700/A5D6A7/767676/",
  "http://via.placeholder.com/1050x700/AED581/767676/",
  "http://via.placeholder.com/1050x700/FFB74D/767676/",
  "http://via.placeholder.com/1050x700/00BFA5/767676/",
  // 6
  "http://via.placeholder.com/100x100/C6FF00/FFFFFF/",
  "http://via.placeholder.com/100x100/F44336/FFFFFF/",
  "http://via.placeholder.com/100x100/673AB7/FFFFFF/",
  "http://via.placeholder.com/100x100/03A9F4/FFFFFF/",
  "http://via.placeholder.com/100x100/4CAF50/FFFFFF/",
  "http://via.placeholder.com/100x100/FF5722/FFFFFF/",
  "http://via.placeholder.com/100x100/607D8B/FFFFFF/",
  "http://via.placeholder.com/100x100/795548/FFFFFF/",
  "http://via.placeholder.com/100x100/8BC34A/FFFFFF/",
  "http://via.placeholder.com/100x100/00BCD4/FFFFFF/",
];

export default images;

export const titleException = ["/"];

const avatars = [
  "/images/avatars/pp_girl.svg",
  "/images/avatars/pp_girl.svg",
  "/images/avatars/pp_girl2.svg",
  "/images/avatars/pp_girl3.svg",
  "/images/avatars/pp_girl4.svg",
  "/images/avatars/pp_girl5.svg",
  "/images/avatars/pp_boy.svg",
  "/images/avatars/pp_boy2.svg",
  "/images/avatars/pp_boy3.svg",
  "/images/avatars/pp_boy4.svg",
  "/images/avatars/pp_boy5.svg",
];

export const dummy = {
  user: {
    name: "Admin",
    title: "Administrator",
    avatar: avatars[6],
    status: "online",
  },
  text: {
    title: "Lorem ipsum",
    subtitle: "Ut a lorem eu odio cursus laoreet.",
    sentences:
      "Donec lacus sem, scelerisque sed ligula nec, iaculis porttitor mauris.",
    paragraph:
      "Sed rutrum augue libero, id faucibus quam aliquet sed. Phasellus interdum orci quam, volutpat ornare eros rhoncus sed. Donec vestibulum leo a auctor convallis. In dignissim consectetur molestie. Vivamus interdum tempor dui, nec posuere augue consequat sit amet. Suspendisse quis semper quam. Nullam nec neque sem.",
    date: "Jan 9, 2018",
  },
};

export const MenuContent = [
  {
    key: "projects",
    name: "Projects",
    icon: "ion-ios-document",
    child: [
      {
        key: "dashboard",
        name: "Dashboard",
        link: "/dashboard",
        icon: "ion-ios-document",
      },

      {
        key: "draw-io",
        name: "Flow Chart",
        link: "/flow-chart",
        icon: "ion-ios-document",
      },
      {
        key: "time-sheet",
        name: "Time Sheet",
        link: "/timesheet",
        icon: "ion-ios-document",
      },
      {
        key: "reimbursement",
        name: "Reimbursement",
        link: "/reimbursement",
        icon: "ion-ios-document",
      },
    ],
  },
];

export const StatusCodes = {
  ["500"]: "Something went wrong",
  ["422"]: "Invalid Data",
  ["404"]: "Not Found",
  ["409"]: "Duplicate",
  ["412"]: "Not Found",
};

export const epic_data = [
  { id: "epic-1", name: "Epic-1" },
  { id: "epic-2", name: "Epic-2" },
  { id: "epic-3", name: "Epic-3" },
  { id: "epic-4", name: "Epic-4" },
];
export const feature_data = [
  { id: "feature-1", name: "Feature-1", p_id: "epic-1" },
  { id: "feature-2", name: "Feature-2", p_id: "epic-1" },
  { id: "feature-3", name: "Feature-3", p_id: "epic-2" },
  { id: "feature-4", name: "Feature-4", p_id: "epic-3" },
];

export const user_story_data = [
  { id: "user_story-1", name: "User Story-1", p_id: "feature-1" },
  { id: "user_story-2", name: "User Story-2", p_id: "feature-1" },
  { id: "user_story-3", name: "User Story-3", p_id: "feature-2" },
  { id: "user_story-4", name: "User Story-4", p_id: "feature-3" },
];
export const task_data = [
  { id: "task-1", name: "Task-1", p_id: "user_story-1" },
  { id: "task-2", name: "Task-2", p_id: "user_story-1" },
  { id: "task-3", name: "Task-3", p_id: "user_story-2" },
  { id: "task-4", name: "Task-4", p_id: "user_story-3" },
];

export const dummy_array = [
  { id: 0, name: "Add New Project" },
  {
    id: 4,
    name: "TFS Dashboard",
    manager_id: 29,
    tfs_name: "TFS Dashboard",
    active: true,
  },
  {
    id: 5,
    name: "5Datainc",
    manager_id: 27,
    tfs_name: "None",
    active: true,
  },

  {
    id: 7,
    name: "TNePass",
    manager_id: 27,
    tfs_name: "TNePass",
    active: true,
  },
  {
    id: 8,
    name: "Visdom",
    manager_id: 28,
    tfs_name: "Visdom",
    active: true,
  },
  {
    id: 9,
    name: "Internal Training",
    manager_id: 29,
    tfs_name: "Training",
    active: true,
  },
  {
    id: 10,
    name: "Right of Way ",
    manager_id: 29,
    tfs_name: "RoW",
    active: true,
  },

  {
    id: 12,
    name: "TN Covid Beds ",
    manager_id: 29,
    tfs_name: "QBT",
    active: true,
  },
  {
    id: 13,
    name: "Cooperative-ECS",
    manager_id: 29,
    tfs_name: "ECS",
    active: true,
  },
  {
    id: 14,
    name: "Law Admin",
    manager_id: 27,
    tfs_name: "Law Admin",
    active: true,
  },
  {
    id: 16,
    name: "CMDA_Survery",
    manager_id: 29,
    tfs_name: "CMDA_Survey",
    active: true,
  },
  {
    id: 17,
    name: "MTIPB",
    manager_id: 28,
    tfs_name: "MTIPB",
    active: true,
  },
  {
    id: 18,
    name: "Vertace Internal",
    manager_id: 22,
    tfs_name: "None",
    active: true,
  },
  {
    id: 19,
    name: "PayRentz",
    manager_id: 29,
    tfs_name: "PayRentz",
    active: true,
  },
  {
    id: 20,
    name: "Vertace Website",
    manager_id: 29,
    tfs_name: "Vertace Website",
    active: true,
  },
  {
    id: 21,
    name: "RMS",
    manager_id: 28,
    tfs_name: "RMS",
    active: true,
  },
  {
    id: 22,
    name: "HRMS_TNeGA",
    manager_id: 29,
    tfs_name: "HRMS_TNeGA",
    active: true,
  },
  {
    id: 23,
    name: "InfyStrat",
    manager_id: 29,
    tfs_name: "None",
    active: true,
  },
  {
    id: 24,
    name: "Vincense",
    manager_id: 29,
    tfs_name: "Vincense",
    active: true,
  },
  {
    id: 25,
    name: "TNeGA Website",
    manager_id: 29,
    tfs_name: "TNeGA_Website",
    active: true,
  },
  {
    id: 26,
    name: "TN IT Website",
    manager_id: 28,
    tfs_name: "TN IT Website",
    active: true,
  },
  {
    id: 27,
    name: "VVD",
    manager_id: 28,
    tfs_name: "VVD",
    active: true,
  },
  {
    id: 28,
    name: "Ariyalur Data",
    manager_id: 22,
    tfs_name: "Ariyalur Data",
    active: true,
  },
  {
    id: 29,
    name: "Vertace PMP",
    manager_id: 22,
    tfs_name: "Vertace PMP",
    active: true,
  },
  {
    id: 31,
    name: "Kiddie Bank",
    manager_id: 22,
    tfs_name: "KiddieBank",
    active: true,
  },
  {
    id: 32,
    name: "EazyRP",
    manager_id: 46,
    tfs_name: "EazyRP",
    active: true,
  },
  {
    id: 33,
    name: "DPI",
    manager_id: 22,
    tfs_name: "DPI",
    active: true,
  },
  {
    id: 34,
    name: "PinkClub",
    manager_id: 22,
    tfs_name: "PinkClub",
    active: true,
  },
  {
    id: 35,
    name: "RUSA",
    manager_id: 22,
    tfs_name: "RUSA",
    active: true,
  },
  {
    id: 36,
    name: "TSP",
    manager_id: 22,
    tfs_name: "TSP",
    active: true,
  },
  {
    id: 37,
    name: "AASC",
    manager_id: 22,
    tfs_name: "AIM",
    active: true,
  },
  {
    id: 39,
    name: "Ariyalur Market App",
    manager_id: 22,
    tfs_name: "AriyalurMarket",
    active: true,
  },
  {
    id: 43,
    name: "NRT",
    manager_id: 22,
    tfs_name: "NRT",
    active: true,
  },
  {
    id: 44,
    name: "Matrimony",
    manager_id: 46,
    tfs_name: "Matrimony",
    active: true,
  },
  {
    id: 45,
    name: "TSP",
    manager_id: 22,
    tfs_name: "TSP",
    active: true,
  },
  {
    id: 46,
    name: "RemoteSensing",
    manager_id: 22,
    tfs_name: "RemoteSensing",
    active: true,
  },
  {
    id: 47,
    name: "PVM",
    manager_id: 22,
    tfs_name: "PVM",
    active: true,
  },
  {
    id: 48,
    name: "Madurai Corporation Tax",
    manager_id: 22,
    tfs_name: "CorpTax",
    active: true,
  },
  {
    id: 49,
    name: "ECTS",
    manager_id: 22,
    tfs_name: "ECTS",
    active: true,
  },
  {
    id: 50,
    name: "TN Projects",
    manager_id: 22,
    tfs_name: "TN Projects",
    active: true,
  },
  {
    id: 51,
    name: "Thoothukudi Corporation Tax",
    manager_id: 22,
    tfs_name: "TutCorpTax",
    active: true,
  },
  {
    id: 52,
    name: "Manipur Forest",
    manager_id: 22,
    tfs_name: "ManipurForest",
    active: true,
  },
  {
    id: 53,
    name: "Fazer",
    manager_id: 46,
    tfs_name: "Fazer",
    active: true,
  },
  {
    id: 54,
    name: "Matrimony",
    manager_id: 22,
    tfs_name: "Matrimony",
    active: true,
  },
  {
    id: 55,
    name: "CBE Police",
    manager_id: 22,
    tfs_name: "CBEPolice",
    active: true,
  },
  {
    id: 56,
    name: "TNFD",
    manager_id: 22,
    tfs_name: "TNFD",
    active: true,
  },
  {
    id: 57,
    name: "AgriGL",
    manager_id: 22,
    tfs_name: "AgriGL",
    active: true,
  },
  {
    id: 58,
    name: "ePass Tenkasi",
    manager_id: 22,
    tfs_name: "ePass Tenkasi",
    active: true,
  },
  {
    id: 59,
    name: "SDAT",
    manager_id: 22,
    tfs_name: "SDAT",
    active: true,
  },
  {
    id: 60,
    name: "Visdomv2",
    manager_id: 22,
    tfs_name: "Visdomv2",
    active: true,
  },
  {
    id: 61,
    name: "Web Training",
    manager_id: 22,
    tfs_name: "Web Training",
    active: true,
  },
  {
    id: 62,
    name: "AIM Test App",
    manager_id: 22,
    tfs_name: "AIMTestApp",
    active: true,
  },
  {
    id: 63,
    name: "TANFED",
    manager_id: 22,
    tfs_name: "TANFED",
    active: true,
  },
  {
    id: 64,
    name: "AgriOSP",
    manager_id: 22,
    tfs_name: "AgriOSP",
    active: true,
  },
  {
    id: 65,
    name: "SDATWebsite",
    manager_id: 22,
    tfs_name: "SDATWebsite",
    active: true,
  },
  {
    id: 66,
    name: "Admin Activities",
    manager_id: 22,
    tfs_name: "Admin Activities",
    active: true,
  },
  {
    id: 67,
    name: "DMS V2",
    manager_id: 46,
    tfs_name: "DMS_V2",
    active: true,
  },
  {
    id: 68,
    name: "Visdom V4",
    manager_id: 46,
    tfs_name: "VisdomV4",
    active: true,
  },
  {
    id: 69,
    name: "ECTS Data Migration",
    manager_id: 22,
    tfs_name: "ECTS-Data-Migration",
    active: true,
  },
  {
    id: 70,
    name: "TNWB",
    manager_id: 46,
    tfs_name: "TNWB",
    active: true,
  },
  {
    id: 71,
    name: "Training",
    manager_id: 22,
    tfs_name: "Training",
    active: true,
  },
  {
    id: 72,
    name: "VisdomV5Dev",
    manager_id: 46,
    tfs_name: "VisdomV5Dev",
    active: true,
  },
  {
    id: 73,
    name: "AVNL",
    manager_id: 46,
    tfs_name: "AVNL",
    active: true,
  },
  {
    id: 74,
    name: "DGGI",
    manager_id: 22,
    tfs_name: "DGGI",
    active: true,
  },
  {
    id: 75,
    name: "Salem Corporation",
    manager_id: 46,
    tfs_name: "Salem-Corp",
    active: true,
  },
  {
    id: 76,
    name: "ECTS CBS",
    manager_id: 22,
    tfs_name: "ECTS-CBS-API",
    active: true,
  },
  {
    id: 77,
    name: "VOffice",
    manager_id: 22,
    tfs_name: "VOffice",
    active: true,
  },
  {
    id: 78,
    name: "YouTurn",
    manager_id: 22,
    tfs_name: "YouTurn Website",
    active: true,
  },
  {
    id: 79,
    name: "V-Mate-App",
    manager_id: 46,
    tfs_name: "V-Mate-App",
    active: true,
  },
  {
    id: 80,
    name: "MASM",
    manager_id: 46,
    tfs_name: "MASM",
    active: true,
  },
  {
    id: 81,
    name: "LFA",
    manager_id: 22,
    tfs_name: "TNLFA",
    active: true,
  },
  {
    id: 82,
    name: "My Harvest",
    manager_id: 22,
    tfs_name: "MyHarvest",
    active: true,
  },
];
// Global UI Action
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const OPEN_SUBMENU = "OPEN_SUBMENU";
export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_RANDOM_THEME = "CHANGE_RANDOM_THEME";
export const CHANGE_MODE = "CHANGE_MODE";
export const CHANGE_GRADIENT = "CHANGE_GRADIENT";
export const CHANGE_DECO = "CHANGE_DECO";
export const CHANGE_BG_POSITION = "CHANGE_BG_POSITION";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_DIRECTION = "CHANGE_DIRECTION";
export const LOAD_PAGE = "LOAD_PAGE";

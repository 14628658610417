import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import lodash from "lodash";

const useStyles = makeStyles()((theme) => ({
  demo: {
    height: "auto",
  },
  divider: {
    margin: `${theme.spacing(3)} 0`,
  },
  field: {
    margin: `${theme.spacing(3)} 5px`,
  },
  root: {
    display: "flex",
    // flexWrap: "wrap",
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ReimbursementFilters = (props) => {
  const {
    selected_employee,
    handle_employee_change,
    handle_type_change,
    handle_project_change,
    selected_project,
    project_list,
    status_list,
    employee_options,
    selected_type,
    handle_status_change,
    selected_status,
  } = props;

  const { classes } = useStyles();

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "15%",
        }}
      >
        <TextField
          id="date"
          label={"From Date"}
          type="date"
          variant="standard"
          // defaultValue={default_value}
          onRateChange={(date) => {
            console.log("date", date);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div
        style={{
          width: "15%",
        }}
      >
        <TextField
          id="date"
          label={"To Date"}
          type="date"
          variant="standard"
          // defaultValue={default_value}
          //   sx={{ width: "10%" }}
          onRateChange={(date) => {
            console.log("date", date);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div
        style={{
          width: "15%",
        }}
      >
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="select">{"Employee Name"}</InputLabel>
          <Select
            variant="standard"
            value={selected_employee}
            onChange={handle_employee_change}
            input={<Input />}
          >
            {employee_options.map((item, index) => {
              return (
                <MenuItem key={index} value={item?.id}>
                  {item?.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          width: "15%",
        }}
      >
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="select">{"Type"}</InputLabel>
          <Select
            variant="standard"
            value={selected_type}
            onChange={handle_type_change}
            input={<Input />}
          >
            <MenuItem key={"food"} value={"food"}>
              {"Food"}
            </MenuItem>
            <MenuItem key={"travel"} value={"travel"}>
              {"Travel"}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          width: "15%",
        }}
      >
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="select">{"Project"}</InputLabel>
          <Select
            variant="standard"
            value={selected_project}
            onChange={handle_project_change}
            input={<Input />}
          >
            {project_list.map((item, index) => {
              return (
                <MenuItem key={index} value={item?.id}>
                  {item?.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          width: "15%",
        }}
      >
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel htmlFor="select">{"Status"}</InputLabel>
          <Select
            variant="standard"
            value={selected_status}
            onChange={handle_status_change}
            input={<Input />}
          >
            {status_list.map((item, index) => {
              return (
                <MenuItem key={index} value={item?.id}>
                  {item?.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
export default ReimbursementFilters;

import Login from "@views/pages/login";
import Redirector from "@views/pages/redirector";
import LeftSidebarLayout from "@views/layouts/LeftSidebarLayout";
// import AccessRightsPage from "@views/pages/access_rights";
import Outer from "@views/layouts/Outer";
import Dashboard from "@views/pages/dashboard";
import ProjectDetails from "@views/pages/project_details";
import DrawIo from "@views/pages/draw_io";
import TimeSheet from "@views/pages/timesheet";
import ChangePassword from "@views/pages/change_password";
import Reimbursement from "@views/pages/reimbursement";
import ReimbursementEmployeeDetails from "@views/components/ui/reimbursement/reimbursement_employee_details";

export const ROUTES = {
  REDIRECTOR: "/",
  LOGIN: "/login",
  CHANGE_PASSWORD: "/change-password",
  DASHBOARD: "/dashboard",
  // ACCESS_RIGHTS: "/access-rights",
  PROJECT_DETAILS: "/project-details/:project_id/:name",
  DRAW_IO: "/flow-chart",
  TIME_SHEET: "/timesheet",
  REIMBURSEMENT: "/reimbursement",
  REIMBURSEMENT_EMPLOYEE_DETAILS: "/reimbursement-details",
};

const myRoutes = [
  {
    path: ROUTES.REDIRECTOR,
    exact: true,
    key: "redirector",
    component: Redirector,
    authenticate: false,
  },
  // {
  //   path: ROUTES.ACCESS_RIGHTS,
  //   key: "Access Rights",
  //   authenticate: false,
  //   component: AccessRightsPage,
  // },
  {
    key: "user_login",
    authenticate: false,
    component: Outer,
    children: [
      {
        path: ROUTES.LOGIN,
        key: "login",
        component: Login,
        authenticate: false,
      },
      {
        path: ROUTES.CHANGE_PASSWORD,
        key: "change-password",
        component: ChangePassword,
        authenticate: false,
      },
    ],
  },
  {
    key: "Admin",
    component: LeftSidebarLayout,

    authenticate: false,
    children: [
      {
        path: ROUTES.DASHBOARD,
        key: "Admin",
        component: Dashboard,
        authenticate: false,
      },
      {
        path: ROUTES.PROJECT_DETAILS,
        key: "Admin",
        component: ProjectDetails,
        authenticate: false,
      },
      {
        path: ROUTES.DRAW_IO,
        key: "Admin",
        component: DrawIo,
        authenticate: false,
      },
      {
        path: ROUTES.TIME_SHEET,
        key: "Admin",
        component: TimeSheet,
        authenticate: false,
      },
      {
        path: ROUTES.REIMBURSEMENT,
        key: "reimbursement",
        component: Reimbursement,
        authenticate: false,
      },
      {
        path: ROUTES.REIMBURSEMENT_EMPLOYEE_DETAILS,
        key: "reimbursement-employee-details",
        component: ReimbursementEmployeeDetails,
        authenticate: false,
      },
    ],
  },
];
export default myRoutes;

import React, { useEffect, useState } from "react";
import PaperBlock from "@views/components/system/PaperBlock/PaperBlock";
import CounterWidget from "@views/components/system/Counter/CounterWidget";
import { Button, Grid, TextField, Tooltip, useMediaQuery } from "@mui/material";

// import {
//   CollectionsBookmark,
//   Edit,
//   OndemandVideo,
//   SupervisorAccount,
// } from "@mui/icons-material";

import SourceIcon from "@mui/icons-material/Source";
import useStyles from "./widget-jss";
import Modal from "@views/components/system/Modal/modal";
import { navigate, navigateWithProps } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import MuiSkeleton from "@views/components/system/Loading/mui_skeleton";
import { dynamicRequest, useDynamicSelector, work_item } from "@services/redux";
import { MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import { dummy_array } from "@helpers/constants";
import colorful_gradient, { add_new_color, gradient_array } from "./colorful";

const DashboardDetails = (props) => {
  const { handle_open_modal } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [project_data, set_project_data] = useState([]);

  const { items, loading } = useDynamicSelector("get_all_projects");

  useEffect(() => {
    if (items) {
      const _items = [
        { id: "demo", name: "Add New Project", status: "" },
        ...items,
      ];
      set_project_data(_items);
    }
  }, [items]);

  const go_to = (name, project_id) => {
    navigateWithProps(`project-details/${project_id}/${name}`, {
      project_id: project_id,
      name: name,
    });
  };

  return (
    <div className={classes.rootCounterFull}>
      <Grid container spacing={2}>
        {loading &&
          dummy_array.map((project, index) => {
            return (
              <Grid key={index} item xs={6} md={3}>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <MuiSkeleton
                    variant_shape={"rectangle"}
                    width={"100%"}
                    height={126}
                    animation_type={"wave"}
                    color={"#f1f1f1"}
                    border_radius={10}
                  />
                </div>
              </Grid>
            );
          })}
        {!loading &&
          project_data?.map((project, index) => {
            return (
              <Grid key={index} item xs={6} md={3}>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (index === 0) {
                      handle_open_modal("add");
                    } else {
                      go_to(project.name, project.id);
                    }
                  }}
                >
                  <CounterWidget
                    // color={
                    //   index === 0
                    //     ? add_new_color[0]
                    //     : gradient_array[
                    //         dummy_array?.length <= 7 ? index : index % 7
                    //       ]
                    // }
                    color={gradient_array[index % gradient_array.length]}
                    start={0}
                    end={index}
                    duration={2}
                    title={project.name}
                    index_value={index}
                  >
                    <SourceIcon className={classes.counterIcon} />
                  </CounterWidget>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};
export default DashboardDetails;

import React, { useEffect, useState } from "react";
import PaperBlock from "@views/components/system/PaperBlock/PaperBlock";
import DashboardDetails from "@views/components/ui/dashboard/dashboard_details";
import { Button, TextField, Tooltip, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useStyles from "@views/components/ui/dashboard/widget-jss";
import Modal from "@views/components/system/Modal/modal";
import ProjectDetails from "./project_details";
import EditProject from "@views/components/ui/project/edit_project";
import { dynamicRequest, get_all_project, sync_tasks } from "@services/redux";
import { useDispatch } from "react-redux";
import { MethodType } from "@helpers/service_call";

const Dashboard = (props) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const [is_modal_open, set_is_modal_open] = useState(false);
  const [modal_type, set_modal_type] = useState("");

  useEffect(() => {
    get_all_project_list();
  }, []);

  const handle_open_modal = (type) => {
    set_is_modal_open(true);
    set_modal_type(type);
  };
  const handle_close_modal = () => {
    set_is_modal_open(false);
  };

  const get_all_project_list = () => {
    let keys = [{ key: "get_all_projects", loading: true }];
    let query = get_all_project;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables, MethodType.POST));
  };

  return (
    <PaperBlock noMargin>
      {/* <div className={classes.actions}>
        <Tooltip title="Add Project">
          <Button
            variant="contained"
            onClick={() => {
              handle_open_modal("add");
            }}
            color="secondary"
            className={classes.button}
          >
            <AddIcon
              className={cx(smUp && classes.leftIcon, classes.iconSmall)}
            />
            {smUp && "Add Project"}
          </Button>
        </Tooltip>
      </div> */}

      <div
        style={{
          minHeight: 300,
          display: "flex",
          // alignItems: "center",
        }}
      >
        <DashboardDetails handle_open_modal={handle_open_modal} />
      </div>
      <Modal
        is_open={is_modal_open}
        handleClose={handle_close_modal}
        scroll={"body"}
        content={<EditProject handle_close_modal={handle_close_modal} />}
        title={"Add Project"}
        footer={false}
        width={400}
      />
    </PaperBlock>
  );
};
export default Dashboard;

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Chip,
  CircularProgress,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaCrown } from "react-icons/fa";
import { GiOpenBook } from "react-icons/gi";
import { MdTask } from "react-icons/md";
import { BsFillTrophyFill } from "react-icons/bs";
import { useLocation, useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  dynamicClear,
  dynamicRequest,
  sync_tasks,
  useDynamicSelector,
  work_item,
} from "@services/redux";
import { MethodType } from "@helpers/service_call";
import { useDispatch } from "react-redux";
import PaperBlock from "@views/components/system/PaperBlock/PaperBlock";

const ProjectDetails = () => {
  const dispatch = useDispatch();
  const { name, project_id } = useParams();
  const [project_name, set_project_name] = useState("");

  const { items: work_items, loading: work_items_loading } =
    useDynamicSelector("work_item");
  const {
    status: sync_tasks_status,
    loading: sync_tasks_loading,
    errors: sync_tasks_status_error,
  } = useDynamicSelector("sync_tasks");

  useEffect(() => {
    if (sync_tasks_status == "success") {
      get_project_work_item(project_id);
      dispatch(dynamicClear("sync_tasks"));
    } else if (sync_tasks_status_error) {
      toast.error(sync_tasks_status_error);
      dispatch(dynamicClear("sync_tasks"));
    }
  }, [sync_tasks_status, sync_tasks_status_error]);
  useEffect(() => {
    if (project_id || name) {
      set_project_name(name);
      get_project_work_item(project_id);
    }
  }, [project_id, name]);

  const sync_tasks_list = () => {
    let keys = [{ key: "sync_tasks", loading: true }];
    let query = `${sync_tasks}/${project_name}`;
    let variables = {};
    dispatch(dynamicRequest(keys, query, variables, MethodType.GET));
  };

  const get_project_work_item = (project_id) => {
    let keys = [{ key: "work_item", loading: true }];
    let query = work_item;
    let variables = {
      project_id,
    };
    dispatch(dynamicRequest(keys, query, variables, MethodType.POST));
  };

  const RecursiveAccordion = ({ data }) => {
    return (
      <>
        {data.type !== "Task" ? (
          <Accordion
            expandIcon={<ExpandMoreIcon />}
            IconButtonProps={{ edge: "end", sx: { mr: 1 } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  {data.type === "Epic" && <FaCrown color="#e06c00" />}
                  {data.type === "Feature" && (
                    <BsFillTrophyFill color="#773b93" />
                  )}
                  {data.type === "User Story" && <GiOpenBook color="#0098c7" />}

                  <Typography
                    style={{
                      textAlign: "left",
                      fontSize: 14,
                    }}
                  >
                    {data.name}
                  </Typography>
                  {data.story_points > 0 ? (
                    <Chip
                      label={data.story_points}
                      size="small"
                      color={"primary"}
                    />
                  ) : (
                    <Chip label={"0"} size="small" color={"error"} />
                  )}
                </div>

                <Typography
                  style={{
                    textAlign: "right",
                    width: "20%",
                    fontSize: 10,
                    marginRight: 10,
                  }}
                >
                  {(data.status === "Active" || data.status === "Inactive") && (
                    <Chip label={data.status} size="small" color="primary" />
                  )}
                  {data.status === "New" && (
                    <Chip label={data.status} size="small" color="default" />
                  )}
                  {data.status === "Closed" && (
                    <Chip label={data.status} size="small" color="success" />
                  )}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {data.children.map((child) => (
                  <RecursiveAccordion key={child.id} data={child} />
                ))}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  marginTop: 10,
                }}
              >
                <MdTask color="#a4880a" />
                <Typography
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                  }}
                >
                  {data.name}
                </Typography>
                <Divider color="black" />
              </div>
              <Typography
                style={{
                  textAlign: "right",
                  width: "20%",
                  fontSize: 10,
                  marginRight: 10,
                }}
              >
                {(data.status === "Active" || data.status === "Inactive") && (
                  <Chip label={data.status} size="small" color="primary" />
                )}
                {data.status === "New" && (
                  <Chip label={data.status} size="small" color="default" />
                )}
                {data.status === "Closed" && (
                  <Chip label={data.status} size="small" color="success" />
                )}
              </Typography>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <PaperBlock noMargin>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: 20,
        }}
      >
        <LoadingButton
          variant="contained"
          color="primary"
          loading={sync_tasks_loading}
          onClick={() => {
            sync_tasks_list();
          }}
        >
          Sync Tasks
        </LoadingButton>
      </div>
      {work_items_loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 300,
          }}
        >
          <CircularProgress />
        </div>
      ) : work_items?.length > 0 ? (
        work_items?.map((item) => (
          <RecursiveAccordion key={item.id} data={item} />
        ))
      ) : (
        <div
          style={{
            width: "100%",
            borderRadius: 5,
            borderColor: "#111",
            borderWidth: 2,
            backgroundColor: "#e8e3e3 ",
            height: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>{`No Work Items`}</Typography>
        </div>
      )}
    </PaperBlock>
  );
};

export default ProjectDetails;

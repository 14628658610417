import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import Chip from "@mui/material/Chip";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useStyles from "./sidebar-jss";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  return <NavLink to={props.to} {...props} innerRef={ref} />;
});

function MainMenu(props) {
  const { classes, cx } = useStyles();
  const [open, setOpen] = useState([]);

  const handleClick = (key, keyParent) => {
    const isOpen = open.includes(key);
    if (isOpen) {
      setOpen(open.filter((item) => item !== key));
    } else {
      setOpen([...open, key]);
    }
    if (props.toggleDrawerOpen) {
      props.toggleDrawerOpen();
    }
    if (props.loadTransition) {
      props.loadTransition(false);
    }
  };

  const getMenus = (menuArray) =>
    menuArray.map((item, index) => {
      if (item.child || item.linkParent) {
        return (
          <div key={index.toString()}>
            <ListItem
              button
              component={LinkBtn}
              to={item.linkParent ? item.linkParent : "#"}
              className={cx(
                classes.head,
                item.icon ? classes.iconed : "",
                open.indexOf(item.key) > -1 ? classes.opened : ""
              )}
              onClick={() => handleClick(item.key, item.keyParent)}
            >
              {item.icon && (
                <ListItemIcon className={classes.icon}>
                  <i className={item.icon} />
                </ListItemIcon>
              )}
              <ListItemText
                classes={{ primary: classes.primary }}
                variant="inset"
                primary={item.name}
              />
              {!item.linkParent && (
                <span>
                  {open.indexOf(item.key) > -1 ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </span>
              )}
            </ListItem>
            {!item.linkParent && (
              <Collapse
                component="div"
                className={cx(
                  classes.nolist,
                  item.keyParent ? classes.child : ""
                )}
                in={open.indexOf(item.key) > -1}
                timeout="auto"
                unmountOnExit
              >
                <List className={classes.dense} component="nav" dense>
                  {getMenus(item.child, "key")}
                </List>
              </Collapse>
            )}
          </div>
        );
      }
      if (item.title) {
        return (
          <ListSubheader
            disableSticky
            key={index.toString()}
            component="div"
            className={classes.title}
          >
            {item.name}
          </ListSubheader>
        );
      }
      return (
        <ListItem
          key={index.toString()}
          button
          exact
          className={classes.nested}
          activeClassName={classes.active}
          component={LinkBtn}
          to={item.link}
          onClick={() => handleClick()}
        >
          <ListItemText
            classes={{ primary: classes.primary }}
            inset
            primary={item.name}
          />
          {item.badge && (
            <Chip
              color="primary"
              label={item.badge}
              className={classes.badge}
            />
          )}
        </ListItem>
      );
    });

  return <div>{getMenus(props.dataMenu)}</div>;
}

MainMenu.propTypes = {
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  dataMenu: PropTypes.array.isRequired,
};

export default MainMenu;

import React, { useState } from "react";
import { Form } from "antd";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const MuiPassword = (props) => {
  const { field, label, message, is_required, class_name } = props;
  const [show_password, set_show_password] = useState(false);

  const handlePasswordVisibility = () => {
    set_show_password(!show_password);
  };

  return (
    <Form.Item name={field} className="no_margin_form_input">
      <TextField
        required={is_required}
        label={label}
        variant="standard"
        type={show_password ? "text" : "password"}
        autoComplete="current-password"
        className={class_name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handlePasswordVisibility}
                edge="end"
              >
                {show_password ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Form.Item>
  );
};
export default MuiPassword;

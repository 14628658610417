import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import PaperBlock from "@views/components/system/PaperBlock/PaperBlock";
import React from "react";
import { useParams, useLocation } from "react-router-dom";
import EmployeeDetailsCard from "./employee_details_card";
import ReimbursementDetails from "./reimbursement_details";

const ReimbursementEmployeeDetails = (props) => {
  const { user_id } = useParams();
  const { props_state } = useLocation();

  return (
    <PaperBlock>
      <Stack
        direction="row"
        style={{
          alignItems: "center",
          marginTop: 10,
          marginBottom: 20,
        }}
        spacing={3}
      >
        <img
          src={require("../../../../assets/images/logo.png")}
          alt={"name"}
          style={{
            width: 80,
            objectFit: "contain",
            borderRadius: 40,
          }}
        />
        <EmployeeDetailsCard />
      </Stack>
      <Typography
        style={{
          fontSize: 16,
          fontFamily: "Montserrat-Bold",
          marginTop: 10,
        }}
      >
        {"Reimbursement Details"}
      </Typography>
      <ReimbursementDetails />
    </PaperBlock>
  );
};
export default ReimbursementEmployeeDetails;

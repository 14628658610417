import { useCheckLogin } from "@helpers/auth";
import { navigate } from "@helpers/navigator";
import { Box } from "@mui/material";
import LoginForm from "@views/components/auth/login_form";

import React, { useEffect, useState } from "react";
const Redirector = (props) => {
  const is_logged_in = useCheckLogin();
  useEffect(() => {
    if (is_logged_in === true) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, [is_logged_in]);

  return (
    <Box flex={1}>
      <LoginForm />
    </Box>
  );
};
export default Redirector;

import { CircularProgress, Divider, Skeleton, Typography } from "@mui/material";
import { useDynamicSelector } from "@services/redux";
import React, { useEffect, useState } from "react";
import { BsFillTrophyFill } from "react-icons/bs";
import { FaCaretRight, FaCrown } from "react-icons/fa";
import { GiOpenBook } from "react-icons/gi";
import { GrProjects } from "react-icons/gr";
import { MdTask } from "react-icons/md";

const TimeSheetProjectList = (props) => {
  const { handle_page_open, items, loading, title, selected_value, width } =
    props;
  const [data, set_data] = useState([]);

  useEffect(() => {
    if (items) {
      set_data(items);
    }
  }, [items]);

  return (
    <>
      {loading && data.length === 0 ? (
        <div
          style={{
            width: width,
            borderRadius: 5,
            borderColor: "#111",
            borderWidth: 2,
            // backgroundColor: "grey",
            backgroundColor: "#e8e3e3 ",

            // backgroundImage: `repeating-linear-gradient(293deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),repeating-linear-gradient(43deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),repeating-linear-gradient(0deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),linear-gradient(90deg, rgb(253,253,253),rgb(253,253,253))`,
            // backgroundImage: `radial-gradient(circle at 57% 36%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 10%,transparent 10%, transparent 100%),radial-gradient(circle at 22% 61%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 68% 97%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 41%,transparent 41%, transparent 100%),radial-gradient(circle at 57% 89%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 30%,transparent 30%, transparent 100%),radial-gradient(circle at 39% 80%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 22%,transparent 22%, transparent 100%),radial-gradient(circle at 88% 71%, hsla(263,0%,78%,0.04) 0%, hsla(263,0%,78%,0.04) 30%,transparent 30%, transparent 100%),linear-gradient(0deg, rgb(255,255,255),rgb(255,255,255))`,
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : data.length > 0 ? (
        <div
          style={{
            width: width,
            borderRadius: 5,
            borderColor: "#111",
            borderWidth: 2,
            backgroundColor: "#e8e3e3 ",

            // backgroundImage: `repeating-linear-gradient(293deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),repeating-linear-gradient(43deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),repeating-linear-gradient(0deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),linear-gradient(90deg, rgb(253,253,253),rgb(253,253,253))`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {title === "Projects" && <GrProjects size={12} color="green" />}
            {title === "Epics" && <FaCrown color="#e06c00" />}
            {title === "Features" && (
              <BsFillTrophyFill size={15} color="#773b93" />
            )}
            {title === "User Stories" && <GiOpenBook color="#0098c7" />}
            {title === "Task" && <MdTask color="#a4880a" />}

            <Typography
              style={{
                fontWeight: "bolder",
                textAlign: "center",
                padding: 5,
                fontSize: 16,
              }}
            >
              {title}
            </Typography>
          </div>

          <Divider />
          {data?.map((x, index) => {
            return (
              <div
                style={{
                  cursor: "pointer",
                  // backgroundColor: selected_value === x.id ? "#FFFF00" : "#fff",
                }}
                key={index}
                onClick={() => {
                  handle_page_open(x.id, x.name);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: 8,
                      fontSize: 14,
                      fontWeight: "inherit",
                      width: "85%",
                    }}
                  >
                    {x.name}
                  </div>
                  <div
                    style={{
                      width: "15%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {selected_value === x.id && <FaCaretRight />}
                  </div>
                </div>
                <Divider />
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            width: width,
            borderRadius: 5,
            borderColor: "#111",
            borderWidth: 2,
            backgroundColor: "#e8e3e3 ",

            // backgroundImage: `repeating-linear-gradient(293deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),repeating-linear-gradient(43deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),repeating-linear-gradient(0deg, hsla(29,0%,74%,0.12) 0px,transparent 7px,hsla(29,0%,74%,0.12) 12px,transparent 19px,hsla(29,0%,74%,0.12) 24px,transparent 31px,hsla(29,0%,74%,0.12) 36px),linear-gradient(90deg, rgb(253,253,253),rgb(253,253,253))`,
            height: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>{`No ${title}`}</Typography>
        </div>
      )}
    </>
  );
};
export default TimeSheetProjectList;

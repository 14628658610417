import React from "react";
import { Form } from "antd";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import lodash from "lodash";
import MuiSkeleton from "../../Loading/mui_skeleton";

const useStyles = makeStyles()((theme) => ({
  demo: {
    height: "auto",
  },
  divider: {
    margin: `${theme.spacing(3)} 0`,
  },
  field: {
    margin: `${theme.spacing(3)} 5px`,
  },
  root: {
    display: "flex",
    // flexWrap: "wrap",
    width: "100%",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const MuiSelect = (props) => {
  const {
    field,
    label,
    message,
    is_required,
    options,
    handle_select_change,
    selected_value,
    labelField,
    valueField,
    is_loading,
  } = props;

  const { classes } = useStyles();
  return (
    <Form.Item
      name={field}
      rules={[
        {
          required: is_required || false,
          message: message || "",
        },
      ]}
      className="no_margin_form_input"
    >
      {is_loading ? (
        <MuiSkeleton
          variant_shape={"rectangle"}
          width={"100%"}
          height={50}
          animation_type={"wave"} //"wave" or "pulse"
          color={"#f1f1f1"}
          border_radius={10}
        />
      ) : (
        <FormControl
          sx={{
            width: "100%",
          }}
          variant="standard"
          className={classes.formControl}
        >
          <InputLabel htmlFor="select">{label}</InputLabel>
          <Select
            variant="standard"
            value={selected_value || ""}
            onChange={handle_select_change}
            input={<Input />}
          >
            {options.map((item, index) => {
              let newObj = {};
              newObj["value"] = lodash.get(item, valueField || "id", null);
              newObj["label"] = lodash.get(item, labelField || "name", null);
              return (
                <MenuItem key={index} value={newObj["value"]}>
                  {newObj["label"]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Form.Item>
  );
};
export default MuiSelect;

import { Input, InputLabel, Typography, Stack } from "@mui/material";
import React from "react";
import { FaUser, FaUserCog } from "react-icons/fa";
import { IoMdCall, IoMdMail } from "react-icons/io";
import EmployeeElements from "./employee/employee_elements";
import CommonLabelAndValue from "./employee/common_label_and_value";

const ReimbursementDetails = (props) => {
  return (
    <Stack direction={"column"}>
      <CommonLabelAndValue label={"Ref No"} value={"RE01"} />
      <CommonLabelAndValue label={"Request Date"} value={"01/04/2024"} />
      <CommonLabelAndValue label={"Claim Date"} value={"11/04/2024"} />
      <CommonLabelAndValue label={"Type"} value={"Food"} />
      <CommonLabelAndValue label={"Project"} value={"myHarvest"} />
      <CommonLabelAndValue
        label={"Description"}
        value={"RE01RE01RE01RE01RE01RE01RE01 RE01RE01RE01 RE01RE01RE01RE01"}
      />
      <CommonLabelAndValue label={"Meals Time"} value={"Lunch"} />
      <CommonLabelAndValue label={"Request Amount(₹)"} value={"150"} />
      <CommonLabelAndValue label={"Bill Submitted"} value={"Yes"} />
      <CommonLabelAndValue
        label={"Bill Submitted"}
        value={"View"}
        type={"file"}
        url={"https://clickdimensions.com/links/TestPDFfile.pdf"}
      />
    </Stack>
  );
};
export default ReimbursementDetails;

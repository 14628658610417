import React, { useState } from "react";
import Hidden from "@mui/material/Hidden";
import useStyles from "../components/auth/user-jss";
import LoginForm from "@views/components/auth/login_form";

function Login() {
  const { classes } = useStyles();
  return (
    <div className={classes.rootFull}>
      <Hidden mdDown>
        <div className="login-background-container" />
      </Hidden>

      <div className={classes.sideFormWrap}>
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;

import {
  pink,
  purple,
  orange,
  teal,
  lightGreen,
  lightBlue,
  indigo,
} from "@mui/material/colors";

const colorful_gradient = [
  // pink[400],
  // indigo.A200,
  // purple[500],
  // teal[500],
  // orange[600],
  // lightGreen[600],
  // lightBlue[500],
  `radial-gradient(circle at 67% 83%, hsla(317,0%,96%,0.05) 0%, hsla(317,0%,96%,0.05) 1%,transparent 1%, transparent 5%,transparent 5%, transparent 100%),
            radial-gradient(circle at 24% 80%, hsla(317,0%,96%,0.05) 0%, hsla(317,0%,96%,0.05) 27%, transparent 27%, transparent 63%,transparent 63%, transparent 100%),
            radial-gradient(circle at 23% 5%, hsla(317,0%,96%,0.05) 0%, hsla(317,0%,96%,0.05) 26%,transparent 26%, transparent 82%,transparent 82%, transparent 100%),
            radial-gradient(circle at 21% 11%, hsla(317,0%,96%,0.05) 0%, hsla(317,0%,96%,0.05) 35%,transparent 35%, transparent 45%,transparent 45%, transparent 100%),
            radial-gradient(circle at 10% 11%, hsla(317,0%,96%,0.05) 0%, hsla(317,0%,96%,0.05) 21%,transparent 21%, transparent 81%,transparent 81%, transparent 100%),
            radial-gradient(circle at 19% 61%, hsla(317,0%,96%,0.05) 0%, hsla(317,0%,96%,0.05) 20%,transparent 20%, transparent 61%,transparent 61%, transparent 100%),
            radial-gradient(circle at 13% 77%, hsla(317,0%,96%,0.05) 0%, hsla(317,0%,96%,0.05) 63%,transparent 63%, transparent 72%,transparent 72%, transparent 100%),
            radial-gradient(circle at 30% 93%, hsla(317,0%,96%,0.05) 0%, hsla(317,0%,96%,0.05) 33%,transparent 33%, transparent 82%,transparent 82%, transparent 100%),
            linear-gradient(90deg, rgb(22, 176, 207),rgb(103, 7, 215))`,
  `radial-gradient(circle at 29% 55%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 4%,transparent 4%, transparent 44%,transparent 44%, transparent 100%),radial-gradient(circle at 85% 89%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 51%,transparent 51%, transparent 52%,transparent 52%, transparent 100%),radial-gradient(circle at 6% 90%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 53%,transparent 53%, transparent 64%,transparent 64%, transparent 100%),radial-gradient(circle at 35% 75%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 6%,transparent 6%, transparent 98%,transparent 98%, transparent 100%),radial-gradient(circle at 56% 75%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 16%,transparent 16%, transparent 23%,transparent 23%, transparent 100%),radial-gradient(circle at 42% 0%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 3%,transparent 3%, transparent 26%,transparent 26%, transparent 100%),radial-gradient(circle at 29% 28%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 51%,transparent 51%, transparent 75%,transparent 75%, transparent 100%),radial-gradient(circle at 77% 21%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 35%,transparent 35%, transparent 55%,transparent 55%, transparent 100%),radial-gradient(circle at 65% 91%, hsla(329,0%,99%,0.05) 0%, hsla(329,0%,99%,0.05) 46%,transparent 46%, transparent 76%,transparent 76%, transparent 100%),linear-gradient(45deg, rgb(83, 91, 235),rgb(76, 11, 174))`,
  `radial-gradient(circle at top right, rgb(13, 141, 190) 0%, rgb(13, 141, 190) 46%,rgb(22, 153, 204) 46%, rgb(22, 153, 204) 49%,rgb(31, 166, 217) 49%, rgb(31, 166, 217) 52%,rgb(40, 178, 231) 52%, rgb(40, 178, 231) 54%,rgb(49, 190, 244) 54%, rgb(49, 190, 244) 100%)`,
  `linear-gradient(306deg, rgba(54, 54, 54, 0.05) 0%, rgba(54, 54, 54, 0.05) 33.333%,rgba(85, 85, 85, 0.05) 33.333%, rgba(85, 85, 85, 0.05) 66.666%,rgba(255, 255, 255, 0.05) 66.666%, rgba(255, 255, 255, 0.05) 99.999%),linear-gradient(353deg, rgba(81, 81, 81, 0.05) 0%, rgba(81, 81, 81, 0.05) 33.333%,rgba(238, 238, 238, 0.05) 33.333%, rgba(238, 238, 238, 0.05) 66.666%,rgba(32, 32, 32, 0.05) 66.666%, rgba(32, 32, 32, 0.05) 99.999%),linear-gradient(140deg, rgba(192, 192, 192, 0.05) 0%, rgba(192, 192, 192, 0.05) 33.333%,rgba(109, 109, 109, 0.05) 33.333%, rgba(109, 109, 109, 0.05) 66.666%,rgba(30, 30, 30, 0.05) 66.666%, rgba(30, 30, 30, 0.05) 99.999%),linear-gradient(189deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 33.333%,rgba(55, 55, 55, 0.05) 33.333%, rgba(55, 55, 55, 0.05) 66.666%,rgba(145, 145, 145, 0.05) 66.666%, rgba(145, 145, 145, 0.05) 99.999%),linear-gradient(90deg, rgb(9, 201, 186),rgb(18, 131, 221))`,
  `linear-gradient(325deg, rgba(23, 27, 35, 0.5) 0%, rgba(23, 27, 35, 0.5) 14.286%,rgba(52, 54, 51, 0.5) 14.286%, rgba(52, 54, 51, 0.5) 28.572%,rgba(80, 81, 67, 0.5) 28.572%, rgba(80, 81, 67, 0.5) 42.858%,rgba(109, 108, 84, 0.5) 42.858%, rgba(109, 108, 84, 0.5) 57.144%,rgba(137, 134, 100, 0.5) 57.144%, rgba(137, 134, 100, 0.5) 71.43%,rgba(166, 161, 116, 0.5) 71.43%, rgba(166, 161, 116, 0.5) 85.716%,rgba(194, 188, 132, 0.5) 85.716%, rgba(194, 188, 132, 0.5) 100.002%),linear-gradient(323deg, rgb(9, 106, 7) 0%, rgb(9, 106, 7) 14.286%,rgb(21, 127, 44) 14.286%, rgb(21, 127, 44) 28.572%,rgb(34, 149, 81) 28.572%, rgb(34, 149, 81) 42.858%,rgb(46, 170, 118) 42.858%, rgb(46, 170, 118) 57.144%,rgb(58, 191, 155) 57.144%, rgb(58, 191, 155) 71.43%,rgb(71, 213, 192) 71.43%, rgb(71, 213, 192) 85.716%,rgb(83, 234, 229) 85.716%, rgb(83, 234, 229) 100.002%)`,
  `radial-gradient(circle at center center, rgb(15, 173, 158),rgb(36, 59, 244))`,
  `repeating-linear-gradient(90deg, rgba(0,0,0,0.09),rgba(0,0,0,0.1),rgba(0,0,0,0.1),rgba(0,0,0,0.04),rgba(0,0,0,0.03),rgba(0,0,0,0.04),rgba(0,0,0,0.02),rgba(0,0,0,0.04),rgba(0,0,0,0.06),rgba(0,0,0,0.1),rgba(0,0,0,0.02),rgba(0,0,0,0.05),rgba(0,0,0,0.04),rgba(0,0,0,0.03),transparent 3px),linear-gradient(45deg, rgb(93, 9, 194),rgb(14, 252, 246))`,
];
export default colorful_gradient;

export const add_new_color = [
  `repeating-linear-gradient(22.5deg, hsla(337,0%,64%,0.2) 0px, hsla(337,0%,64%,0.2) 0px,transparent 0px, transparent 1px,hsla(337,0%,64%,0.2) 1px, hsla(337,0%,64%,0.2) 4px,transparent 4px, transparent 5px,hsla(337,0%,64%,0.2) 5px, hsla(337,0%,64%,0.2) 8px),repeating-linear-gradient(157.5deg, hsla(337,0%,64%,0.2) 0px, hsla(337,0%,64%,0.2) 0px,transparent 0px, transparent 1px,hsla(337,0%,64%,0.2) 1px, hsla(337,0%,64%,0.2) 4px,transparent 4px, transparent 5px,hsla(337,0%,64%,0.2) 5px, hsla(337,0%,64%,0.2) 8px),linear-gradient(135deg, rgb(168, 168, 168),rgb(60, 60, 60))`,
];

export const gradient_array = [
  `linear-gradient(57deg, rgba(61, 61, 61, 0.04) 0%, rgba(61, 61, 61, 0.04) 20%,rgba(228, 228, 228, 0.04) 20%, rgba(228, 228, 228, 0.04) 100%),linear-gradient(184deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 74%,rgba(167, 167, 167, 0.04) 74%, rgba(167, 167, 167, 0.04) 100%),linear-gradient(143deg, rgba(10, 10, 10, 0.04) 0%, rgba(10, 10, 10, 0.04) 43%,rgba(169, 169, 169, 0.04) 43%, rgba(169, 169, 169, 0.04) 100%),linear-gradient(8deg, rgba(238, 238, 238, 0.04) 0%, rgba(238, 238, 238, 0.04) 96%,rgba(162, 162, 162, 0.04) 96%, rgba(162, 162, 162, 0.04) 100%),linear-gradient(90deg, rgb(199, 66, 19),rgb(209, 183, 108))`,
  `linear-gradient(135deg, rgba(57, 57, 57, 0.04) 0%, rgba(57, 57, 57, 0.04) 37%,rgba(143, 143, 143, 0.04) 37%, rgba(143, 143, 143, 0.04) 100%),linear-gradient(45deg, rgba(11, 11, 11, 0.04) 0%, rgba(11, 11, 11, 0.04) 30%,rgba(166, 166, 166, 0.04) 30%, rgba(166, 166, 166, 0.04) 100%),linear-gradient(90deg, rgba(231, 231, 231, 0.04) 0%, rgba(231, 231, 231, 0.04) 63%,rgba(193, 193, 193, 0.04) 63%, rgba(193, 193, 193, 0.04) 100%),linear-gradient(0deg, rgba(162, 162, 162, 0.04) 0%, rgba(162, 162, 162, 0.04) 14%,rgba(210, 210, 210, 0.04) 14%, rgba(210, 210, 210, 0.04) 100%),linear-gradient(90deg, rgb(5, 48, 167),rgb(22, 204, 209))`,
  `linear-gradient(56deg, rgba(254, 254, 254, 0.05) 0%, rgba(254, 254, 254, 0.05) 69%,rgba(160, 160, 160, 0.05) 69%, rgba(160, 160, 160, 0.05) 100%),linear-gradient(194deg, rgba(102, 102, 102, 0.02) 0%, rgba(102, 102, 102, 0.02) 60%,rgba(67, 67, 67, 0.02) 60%, rgba(67, 67, 67, 0.02) 100%),linear-gradient(76deg, rgba(169, 169, 169, 0.06) 0%, rgba(169, 169, 169, 0.06) 89%,rgba(189, 189, 189, 0.06) 89%, rgba(189, 189, 189, 0.06) 100%),linear-gradient(326deg, rgba(213, 213, 213, 0.04) 0%, rgba(213, 213, 213, 0.04) 45%,rgba(66, 66, 66, 0.04) 45%, rgba(66, 66, 66, 0.04) 100%),linear-gradient(183deg, rgba(223, 223, 223, 0.01) 0%, rgba(223, 223, 223, 0.01) 82%,rgba(28, 28, 28, 0.01) 82%, rgba(28, 28, 28, 0.01) 100%),linear-gradient(3deg, rgba(20, 20, 20, 0.06) 0%, rgba(20, 20, 20, 0.06) 62%,rgba(136, 136, 136, 0.06) 62%, rgba(136, 136, 136, 0.06) 100%),linear-gradient(200deg, rgba(206, 206, 206, 0.09) 0%, rgba(206, 206, 206, 0.09) 58%,rgba(6, 6, 6, 0.09) 58%, rgba(6, 6, 6, 0.09) 100%),linear-gradient(304deg, rgba(162, 162, 162, 0.07) 0%, rgba(162, 162, 162, 0.07) 27%,rgba(24, 24, 24, 0.07) 27%, rgba(24, 24, 24, 0.07) 100%),linear-gradient(186deg, rgba(166, 166, 166, 0.04) 0%, rgba(166, 166, 166, 0.04) 5%,rgba(210, 210, 210, 0.04) 5%, rgba(210, 210, 210, 0.04) 100%),linear-gradient(90deg, rgb(26, 118, 64),rgb(32, 207, 121),rgb(78, 196, 128))`,
  `linear-gradient(78deg, rgba(96, 96, 96, 0.04) 0%, rgba(96, 96, 96, 0.04) 35%,rgba(220, 220, 220, 0.04) 35%, rgba(220, 220, 220, 0.04) 100%),linear-gradient(150deg, rgba(83, 83, 83, 0.04) 0%, rgba(83, 83, 83, 0.04) 71%,rgba(6, 6, 6, 0.04) 71%, rgba(6, 6, 6, 0.04) 100%),linear-gradient(311deg, rgba(203, 203, 203, 0.04) 0%, rgba(203, 203, 203, 0.04) 58%,rgba(3, 3, 3, 0.04) 58%, rgba(3, 3, 3, 0.04) 100%),linear-gradient(137deg, rgba(110, 110, 110, 0.04) 0%, rgba(110, 110, 110, 0.04) 11%,rgba(226, 226, 226, 0.04) 11%, rgba(226, 226, 226, 0.04) 100%),linear-gradient(90deg, rgb(215, 19, 84),rgb(234, 119, 123))`,
  `linear-gradient(22.5deg, rgba(221, 221, 221, 0.04) 0%, rgba(221, 221, 221, 0.04) 56%,rgba(7, 7, 7, 0.04) 56%, rgba(7, 7, 7, 0.04) 100%),linear-gradient(112.5deg, rgba(227, 227, 227, 0.04) 0%, rgba(227, 227, 227, 0.04) 14%,rgba(56, 56, 56, 0.04) 14%, rgba(56, 56, 56, 0.04) 100%),linear-gradient(45deg, rgba(39, 39, 39, 0.04) 0%, rgba(39, 39, 39, 0.04) 34%,rgba(196, 196, 196, 0.04) 34%, rgba(196, 196, 196, 0.04) 100%),linear-gradient(0deg, rgba(20, 20, 20, 0.04) 0%, rgba(20, 20, 20, 0.04) 84%,rgba(75, 75, 75, 0.04) 84%, rgba(75, 75, 75, 0.04) 100%),linear-gradient(90deg, rgb(175, 31, 79),rgb(201, 35, 98))`,

  // `linear-gradient(56deg, rgba(164, 164, 164, 0.05) 0%, rgba(164, 164, 164, 0.05) 68%,rgba(9, 9, 9, 0.05) 68%, rgba(9, 9, 9, 0.05) 100%),linear-gradient(135deg, rgba(18, 18, 18, 0.05) 0%, rgba(18, 18, 18, 0.05) 36%,rgba(90, 90, 90, 0.05) 36%, rgba(90, 90, 90, 0.05) 100%),linear-gradient(94deg, rgba(70, 70, 70, 0.05) 0%, rgba(70, 70, 70, 0.05) 90%,rgba(243, 243, 243, 0.05) 90%, rgba(243, 243, 243, 0.05) 100%),linear-gradient(141deg, rgba(242, 242, 242, 0.05) 0%, rgba(242, 242, 242, 0.05) 60%,rgba(70, 70, 70, 0.05) 60%, rgba(70, 70, 70, 0.05) 100%),linear-gradient(90deg, rgb(233, 217, 214),rgb(234, 152, 2))`,
];

import { Input, InputLabel, Typography, Stack } from "@mui/material";
import React from "react";
import { FaUser, FaUserCog } from "react-icons/fa";
import { IoMdCall, IoMdMail } from "react-icons/io";
import EmployeeElements from "./employee/employee_elements";

const EmployeeDetailsCard = (props) => {
  return (
    <Stack direction={"column"}>
      <EmployeeElements
        label={"Ajith"}
        icon={<FaUser size={12} color="lightblue" />}
      />
      <EmployeeElements
        label={"Software Developer"}
        icon={<FaUserCog size={12} color="lightblue" />}
      />
      <EmployeeElements
        label={"ajith@vertace.com"}
        icon={<IoMdMail size={12} color="lightblue" />}
      />
      <EmployeeElements
        label={"8072086115"}
        icon={<IoMdCall size={12} color="lightblue" />}
      />
    </Stack>
  );
};
export default EmployeeDetailsCard;

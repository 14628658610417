import { Typography, Stack } from "@mui/material";
import React from "react";

const EmployeeElements = (props) => {
  const { icon, label } = props;
  return (
    <Stack
      direction="row"
      style={{
        alignItems: "center",
        marginTop: 2,
      }}
      spacing={1}
    >
      {icon}
      <Typography
        style={{
          fontSize: 12,
          fontFamily: "Montserrat-Bold",
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
};
export default EmployeeElements;

import { Typography, Stack } from "@mui/material";
import React from "react";

const CommonLabelAndValue = (props) => {
  const { label, value, type } = props;
  return (
    <Stack
      direction="row"
      style={{
        alignItems: "center",
        width: "100%",
        marginTop: 10,
      }}
    >
      <div
        style={{
          width: "20%",
        }}
      >
        <Typography
          style={{
            fontSize: 12,
            fontFamily: "Montserrat-Bold",
            color: "lightgrey",
          }}
        >
          {label}
        </Typography>
      </div>
      <div>:</div>
      <div
        style={{
          width: "80%",
          justifyContent: "flex-start",
          marginLeft: 5,
        }}
      >
        {type === "file" ? (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {}}
          >
            <Typography
              style={{
                fontSize: 12,
                fontFamily: "Montserrat-Bold",
              }}
            >
              {value}
            </Typography>
          </div>
        ) : (
          <Typography
            style={{
              fontSize: 12,
              fontFamily: "Montserrat-Bold",
            }}
          >
            {value}
          </Typography>
        )}
      </div>
    </Stack>
  );
};
export default CommonLabelAndValue;
